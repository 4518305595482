<template>
  <div class="rateNoSelect">
    <!-- 验证评级——历史分析 -->
    <div class="rateIndustryEchart flexBox">
      <div class="rateEchartItems">
        <div class="rateEchartTips flexCenter">
          <span class="title">Rating analysis of companies in the same industry with different years in business</span>
        </div>
        <ul class="lengedBox flexCenter lengedBarBox">
          <li class="flexCenter flex_center_start_box lengedBoxItems m-lf lengedBoxItemsText" >
            <span class="redkuai" > </span>
            <span class="">Subject Company</span>
          </li>
        </ul>
        <div class="flex_center_between_box">
        <div
          ref="echarts1"
          class="rateEchartItems1"
        />
        <div class="rateEchartInfo">
          <Table
            :title="'Subject rating comparing with its peer companies with the'"
            :table-data="echartsList1"
            :col-data="colData4"
          />
        </div>
        </div>
      </div>
      <div class="rateEchartItems">
        <div class="rateEchartTips flexCenter">
          <span class="title">Subject's rating history comparing with its peers with the same history</span>
        </div>
        <!-- <div class="rateEchartSelectInfo">
          <div class="flexEnd rateEchartSelect">
            <el-select v-model="selectYears" placeholder="请选择" @change="selectOpenedYears">
              <el-option
                v-for="(val, index) in openedYears"
                :key="index"
                :label="val.label"
                :value="val.value"
              />
            </el-select>
          </div>
        </div> -->
        <ul class="lengedBox flexCenter lengedLineBox">
          <li class="flexCenter flex_center_start_box lengedBoxItems m-lf lengedBoxItemsText" >
            <span class="redkuai" > </span>
            <span class="">Subject Company</span>
          </li>
        </ul>
        <div class="flex_center_between_box">
        <div
          ref="echarts2"
          class="rateEchartItems2"
        />
        <div class="rateEchartInfo">
          <Tables
            :title="'Subject rating in its group:'"
            :table-data="echartsList2"
            :col-data="colData1"
          />
        </div>
        </div>
      </div>
    </div>
    <div class="rateIndustryEchart flexBox" style="margin-top: 10px;">
      <div class="rateEchartItems">
        <div class="rateEchartTips flexCenter">
          <span class="title">Rating ranking of companies with same history</span>
        </div>
        <div class="flex_center_between_box" style="margin-top: 1.42857143rem;">
        <div class="rateEchartItems3">
          <el-table
            :data="tableData"
            class="title rate_scale_table"
            stripe
            :header-cell-style="{textAlign: 'center'}"
            :cell-class-name="tableCellClass"
       
          >
            <el-table-column
              prop="date"
              :label="'Rank'"
              align="center"
               width="80"
            />
            <el-table-column
              prop="name"
              :label="'Company Name'"
              show-overflow-tooltip
              align="left"
               min-width="170"
            >
              <template slot-scope="scope">
                <span class="jumpName active" style="cursor: pointer" @click="jumpClick(scope.row)">{{ lang === 'cn' ? scope.row.name : scope.row.nameEn }}</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="province"
              min-width="170"
              :label="'Years in Business'"
              show-overflow-tooltip
              align="center"
            >
              <template slot-scope="scope">
                <span>{{ lang === 'cn' ? scope.row.province : scope.row.provinceEn }}</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="city"
              :label="'Rating'"
              align="center"
            
            />
          </el-table>
        </div>
        <div class="rateEchartInfo">
          <Table
            :title="'Subject rating comparing with its peer companies with the'"
            :table-data="echartsList4"
            :col-data="colData3"
          />
        </div>
        </div>
      </div>
      <div class="rateEchartItems">
        <div class="rateEchartTips flexCenter">
          <span class="title">Rating statistics of companies with the same years in business</span>
        </div>
        <div class="flex_center_between_box" style="margin-top: 1.42857143rem;">
        <div
          ref="echarts4"
          class="rateEchartItems4"
        />
        <div class="rateEchartInfo" style="width: 35%;">
          <Table
            :title="'Subject rating comparing with its peer companies with the'"
            :table-data="echartsList5"
            :col-data="colData3"
          />
        </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Table from '@/components/table'
import Tables from '@/components/tables'
import { ratingHistory } from "@/api/companyApi";
import { mapGetters } from 'vuex'
export default {
  components: {
    Table: Table,
    Tables: Tables
  },
  props: {
    industryCode: {
      type: String,
      default: ''
    },
    companyAaaid: {
      type: String,
      default: ''
    },
    copyCompany: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      iconWidth: 16,
      iconWidth1: 14,
      fontSize: 12,
      pieFontSize: 16,
      start: 'image://' + require('@/assets/images/system/credit/start.png'),
      start1: require('@/assets/images/system/credit/start.png'),
      value: '1',
      echartsList1: [],
      echartsList2: [],
      echartsList4: [
        {
          indicators: '观察的年限',
          value: '10年以上'
        }
      ],
      echartsList5: [],
      colData1: [
        {
          prop: 'indicators',
          align: 'left'
        },
        {
          prop: 'rating3a',
          align: 'right'
        },
        {
          prop: 'num',
          align: 'right'
        },
        {
          prop: 'value',
          align: 'right'
        }
      ],
      colData3: [
        {
          prop: 'indicators',
          align: 'left'
        },
        {
          prop: 'value',
          align: 'right'
        }
      ],
      colData4: [
        {
          prop: 'indicators',
          align: 'left'
        },
        {
          prop: 'rating3a',
          align: 'right'
        },
        {
          prop: 'rating',
          align: 'right'
        },
        {
          prop: 'value',
          align: 'right'
        }
      ],
      kinds: [],
      five: '',
      years: [],
      newYear: [],
      tableData: [],
      echarts1Data: [],
      maxYearsData: {},
      conclusion: '',
      conclusionEn: '',
      selectYears: '15年及以上',
      firstChart: null,
      secondChart: null,
      thirdChart: null,
      fourChart: null,
      lang:'en'
    }
  },
  computed: {
    ...mapGetters({
      lang: 'lang'
    }),
    openedYears () {
      const list = [
        { label:'0-3 years', value: '3年以下' },
        { label: '3-5 years', value: '3-5年' },
        { label: '5-10 years', value: '5-10年' },
        { label: '10-15 years', value: '10-15年' },
        { label: 'More than 15 years', value: '15年及以上' }
      ]
      return list
    }
  },
  watch: {
    lang () {
      this.echarts1(this.fontSize)
      this.echarts2(this.fontSize)
      this.echartLang()
      if (this.lang === 'en') {
        this.colData4[0].prop = 'indicatorsEn'
      } else {
        this.colData4[0].prop = 'indicators'
      }
    }
  },
  mounted () {
    window.addEventListener('resize', this.resizeFn)
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.resizeFn)
  },
  created () {
    this.History()
  },
  methods: {
    tableCellClass ({ row, column, rowIndex, columnIndex }) {
      if (row.name === this.copyCompany) {
        return 'cellBg'
      }
    },
    History () {
      const paramsThree = {
        industryCode: this.industryCode,
        aaaId: this.companyAaaid,
        companyName: this.copyCompany,
        years: this.selectYears,
        country:this.$route.query.companyCountry
      }
      this.num = []
      this.List = []
      this.year = []
      this.years = []
      this.xAxisnum = []
      this.yAxisnum = []
      this.zAxisnum = []
      this.wAxisnums = []
      this.kinds = []
      this.newYear = []
      this.echarts1Data = []
      if (this.lang === 'en') {
        this.colData1[0] = { prop: 'indicatorsEn', align: 'left' }
        this.colData3[0] = { prop: 'indicatorsEn', align: 'left' }
      } else {
        this.colData1[0] = { prop: 'indicators', align: 'left' }
        this.colData3[0] = { prop: 'indicators', align: 'left' }
      }
      if (this.lang === 'en') {
        this.colData4[0].prop = 'indicatorsEn'
      } else {
        this.colData4[0].prop = 'indicators'
      }
      ratingHistory(paramsThree).then(res => {
        this.maxYearsData = res.data.targetMaxYear
        this.echarts1Data = res.data.charts1.sort(function (a, b) {
          return a.year - b.year
        })
        const echart2Date = {}
        res.data.charts2.map(item => {
          if (!echart2Date[item.year]) {
            echart2Date[item.year] = {}
          }
          if (item.rating_3a) {
            if (item.index_type === '最差值') {
              echart2Date[item.year].min = item.rating_3a.replace('3A', '') ? item.rating_3a.replace('3A', '') : ''
            } else if (item.index_type === '最好值') {
              echart2Date[item.year].max = item.rating_3a.replace('3A', '') ? item.rating_3a.replace('3A', '') : ''
            } else if (item.index_type === '平均值') {
              echart2Date[item.year].avg = item.rating_3a.replace('3A', '') ? item.rating_3a.replace('3A', '') : ''
            }
            else if (item.index_type === '目标公司') {
            // echart3Date[item.year].targe = item?.rating_grade_3a?.replace('3A', '') ? item?.rating_grade_3a?.replace('3A', '') : ''
            echart2Date[item.year].targe = item?.rating_3a?.replace('3A', '') ? item?.rating_3a?.replace('3A', '') : ''
            // 新字段

          }
          }
        })
        for (var key in echart2Date) {
          const d = echart2Date[key]
          this.years.push(key || '')
          this.xAxisnum.push(d.max)
          this.yAxisnum.push(d.min)
          this.zAxisnum.push(d.avg)
          this.wAxisnums.push(d.targe)
        }
        this.newYear = this.years.sort() ? this.years.sort() : ''
        this.echartsList1 = res.data.table1.map(item => {
          if (item.indicators === '目标公司') {
            item.value = ''
            return item
          } else {
            item.value = '+' + (item.value * 100).toFixed(2) + '%'
            // item.value = '+' + (item.value * 100).toFixed(2)
            return item
          }
        })
        this.echartsList2 = res.data.table2.map(item => {
          if (item.indicators === '目标公司') {
            item.value = ''
            return item
          } else {
            item.value = '+' + (item.value * 100).toFixed(2) + '%'
            // item.value = '+' + (item.value * 100).toFixed(2)
            return item
          }
        })
        const numbers =
        {
          indicators: '企业的历史年限',
          indicatorsEn: 'Historical years of the enterprise',
          value: this.lang === 'en' ? res.data.targetMaxYear.yearsEn : res.data.targetMaxYear.years
        }
        this.echartsList2.push(numbers)
        this.tableData = res.data.table3.map(item => {
          item.date = item.rank
          item.name = item.companyName
          item.nameEn = item.companyNameEn
          item.province = item.size
          item.provinceEn = item.sizeEn
          item.city = item.rating
          return item
        })
        res.data.ratingPies.forEach(item => {
          this.List.push({ name: item.indexType, value: item.num })
        })
        const rate = res.data.targetMaxYear.years
        if (rate === '3年以下') {
          this.conclusion = '初创企业'
          this.conclusionEn = 'New Startup'
        } else if (rate === '3-5年') {
          this.conclusion = '年轻企业'
          this.conclusionEn = 'Startup Company'
        } else if (rate === '5-10年') {
          this.conclusion = '历史一般'
          this.conclusionEn = '5-10 Years'
        } else if (rate === '10-15年') {
          this.conclusion = '10-15年'
          this.conclusionEn = '10-15 Years'
        } else if (rate === '15年及以上') {
          this.conclusion = '历史悠久'
          this.conclusionEn = 'Long history Company'
        }
        this.kinds = res.data.targetMaxYear ? res.data.targetMaxYear : ''
        if (this.kinds && this.kinds['ratingGrade']) {
          this.five = this.kinds['ratingGrade'].replace('3A', '') ? this.kinds['ratingGrade'].replace('3A', '') : ''
        }
        this.$nextTick(() => {
          this.resizeFn()
          this.echartLang()
        })
      })
    },
    selectOpenedYears () {
      const params = {
        industryCode: this.industryCode,
        aaaId: this.companyAaaid,
        companyName: this.copyCompany,
        years: this.selectYears
      }
      ratingHistory(params).then(res => {
        const echart2Date = {}
        res.data.charts2.map(item => {
          if (!echart2Date[item.year]) {
            echart2Date[item.year] = {}
          }
          if (item.rating_3a) {
            if (item.index_type === '最差值') {
              echart2Date[item.year].min = item.rating_3a.replace('3A', '') ? item.rating_3a.replace('3A', '') : ''
            } else if (item.index_type === '最好值') {
              echart2Date[item.year].max = item.rating_3a.replace('3A', '') ? item.rating_3a.replace('3A', '') : ''
            } else if (item.index_type === '平均值') {
              echart2Date[item.year].avg = item.rating_3a.replace('3A', '') ? item.rating_3a.replace('3A', '') : ''
            }
          }
        })
        this.years = []
        this.xAxisnum = []
        this.yAxisnum = []
        this.zAxisnum = []
        for (var key in echart2Date) {
          const d = echart2Date[key]
          this.years.push(key || '')
          this.xAxisnum.push(d.max)
          this.yAxisnum.push(d.min)
          this.zAxisnum.push(d.avg)
        }
        this.echartsList2 = res.data.table2.map(item => {
          if (item.indicators === '目标公司') {
            item.value = ''
            return item
          } else {
            item.value = '+' + (item.value * 100).toFixed(2) + '%'
            return item
          }
        })
        this.maxYearsData.years = res.data.targetMaxYear.years
        this.maxYearsData.yearsEn = res.data.targetMaxYear.yearsEn
        const numbers =
        {
          indicators: '企业的历史年限',
          indicatorsEn: 'Historical years of the enterprise',
          value: this.lang === 'en' ? res.data.targetMaxYear.yearsEn : res.data.targetMaxYear.years
        }
        this.echartsList2.push(numbers)
        this.echarts2(this.fontSize)
      })
    },
    jumpClick (row) {
      // const routeQuery = this.$router.resolve({
      //   path: '/system/credit',
      //   query: {
      //     aaaId: row.aaaId,
      //     companyName: row.name,
      //     companyNameEn: row.nameEn
      //   }
      // })
      // window.open(routeQuery.href, '_blank')
      // this.$router.push({
      //   path: '/system/industryResearch',
      //   query: {
      //     aaaId: row.aaaId,
      //     companyName: row.name,
      //     companyNameEn: row.nameEn
      //   }
      // })
    },
    echarts1 (fontSize) {
      const echarstData = []
      const selectData = []
      for (var item of this.echarts1Data) {
        if (item.rating_3a) {
          if (this.lang === 'en') {
            echarstData.push([item.years_en, item.rating_3a.replace('3A', ''), item.year])
          } else {
            echarstData.push([item.years, item.rating_3a.replace('3A', ''), item.year])
          }
        }
      }
      let xAxisData = ['3年以下', '3-5年', '5-10年', '10-15年', '15年及以上']
      if (this.lang === 'en') {
        xAxisData = ['0-3 years', '3-5 years', '5-10 years', '10-15 years', 'More than 15 years']
        selectData.push(this.kinds['yearsEn'])
      } else {
        selectData.push(this.kinds['years'])
      }
      if (this.kinds && this.kinds['ratingGrade']) {
        selectData.push(this.kinds['ratingGrade'].replace('3A', ''))
      }
      if (this.firstChart !== null) {
        this.firstChart.dispose()
      }
      this.firstChart = this.$echarts.init(this.$refs.echarts1)
      // const newYear = this.kinds.year ? this.kinds.year : ''
      // const Five = this.five.replace('3A', '') ? this.five.replace('3A', '') : ''
      const option = {
        color: ['#7C95C5'],
        grid: {
          left: '0%',
          right: '5%',
          bottom: '0%',
          top: '12%',
          containLabel: true
        },
        tooltip: {
          show: true,
          formatter: (params) => {
            if (params.color === '#FF6A6A') {
              return 'Subject Company<br/>' + 'Years in Biz:' + params.value[0] + '<br/>' + 'Rating: 3A' + params.value[1]
            } else {
              return 'Years in Biz: '   + params.value[0] + '<br/>' +  'Rating: 3A' + params.value[1]
            }
          }
        },
        xAxis: [
          {
            type: 'category',
            data: xAxisData,
            axisLine: {
              show: true,
              lineStyle: {
                width: 1,
                color: '#1290C9'
              }
            },
            axisTick: {
              show: false,
              alignWithLabel: true
            },
            axisLabel: {
              fontSize: fontSize,
              color: '#8398BB'
            }
          }
        ],
        yAxis: {
          type: 'value',
          max: 19,
          min: 1,
          splitNumber: 4,
          inverse: true,
          splitLine: {
            lineStyle: {
              width: 1,
              type: 'dashed',
              color: '#8398BB'
            }
          },
          axisLine: {
            show: true,
            lineStyle: {
              width: 1,
              color: '#142746'
            }
          },
          axisLabel: {
            fontSize: fontSize,
            color: '#8398BB',
            formatter: '3A{value}'
          },
          axisTick: {
            show: false
          }
        },
        series: [
          {
            // name: '收入',
            data: echarstData,
            type: 'scatter',
            itemStyle: {
              color: '#7C95C5'
              // function (params) {
              //   if (params.data[1] === Five) {
              //     return '#DD5145'
              //   } else {
              //     return params.color
              //   }
              // }
            },
            symbolSize: 10
          },
          {
            data: [selectData],
            type: 'scatter',
            itemStyle: {
              color: '#DD5145'
            },
            symbolSize: 10
          }
          // {
          //   type: 'bar',
          //   barWidth: '48%',
          //   label: {
          //     show: false
          //   },
          //   itemStyle: {
          //     color: function (params) {
          //       if (params.name === newYear) {
          //         return '#E85367'
          //       } else {
          //         return params.color
          //       }
          //     }
          //   },
          //   data: this.num
          // }
        ]
      }
      this.firstChart.setOption(option)
      this.firstChart.resize()
    },
    // echarts根据中英文显示legend文字
    echartLang () {
      // var newOption = this.secondChart.getOption()
      // newOption.legend = { data: ['Max', 'Min', 'Average'] }
      // newOption.series[0].name = 'Max'
      // newOption.series[1].name = 'Min'
      // newOption.series[2].name = 'Average'
      // this.secondChart.setOption(newOption)
      if (this.lang === 'en') {
        this.colData1[0] = { prop: 'indicatorsEn', align: 'left' }
        this.colData3[0] = { prop: 'indicatorsEn', align: 'left' }
      } else {
        this.colData1[0] = { prop: 'indicators', align: 'left' }
        this.colData3[0] = { prop: 'indicators', align: 'left' }
      }
      const numbers =
        {
          indicators: '企业的历史年限',
          indicatorsEn: 'Historical years of the enterprise',
          value: this.lang === 'en' ? this.maxYearsData.yearsEn : this.maxYearsData.years
        }
      this.echartsList2[this.echartsList2.length - 1] = numbers
      this.echartsList4 = [...this.echartsList1.filter(i => i.indicators !== '目标公司'),
        {
          indicators: '观察的年限',
          indicatorsEn: 'Years of observation',
          value: this.lang === 'en' ? this.maxYearsData.yearsEn : this.maxYearsData.years
        }
      ]
      this.echartsList4.push('')
      const four = [
        {
          indicators: '观察的年限',
          indicatorsEn: 'Years of observation',
          value: this.lang === 'en' ? this.maxYearsData.yearsEn : this.maxYearsData.years
        },
        {
          indicators: '性质表现',
          indicatorsEn: 'Performance',
          value: this.lang === 'en' ? this.conclusionEn : this.conclusion
        }
      ]
      this.echartsList5 = [...this.echartsList1.filter(i => i.indicators !== '目标公司'), ...four]
    },
    echarts2 (fontSize) {
      if (this.secondChart !== null) {
        this.secondChart.dispose()
      }
      this.secondChart = this.$echarts.init(this.$refs.echarts2)
      const data = this.years.sort()
      const v = this.kinds.year ? this.kinds.year : ''
      const five = this.five
      const option = {
        color: ['#81C97A', '#1A2332', '#1290C9', '#FF6A6A'],
        grid: {
          left: '0%',
          right: '5%',
          bottom: '0%',
          top: '12%',
          containLabel: true
        },
        legend: {
          left: '35%',
          top: '-2%',
          textStyle: {
            lineHeight: '26'
          },
          data: ['Max', 'Min', 'Average']
        },
        tooltip: {
          // trigger: 'item',
          showDelay: 0,
          formatter: (params) => {
            if (params.value) {
              if (params.value.length > 1) {
                return  'Year: ' + params['name'] + '<br/>' + params.seriesName + ' : ' +
                  '3A' + params.value
              } else {
                return 'Year: ' + params['name'] + '<br/>' + params.seriesName + ' : ' +
                  '3A' + params.value
              }
            } else {
              if (params.componentType === 'markPoint') {
                return +'Subject Company : ' + params.data.xAxis + '<br/>' + 'Rating: ' + '3A' + params.data.yAxis
              }
            }
          }
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: data,
          splitLine: {
            show: true,
            lineStyle: {
              width: 1,
              type: 'dashed',
              color: '#8398BB'
            }
          },
          axisLine: {
            show: true,
            lineStyle: {
              width: 1,
              color: '#142746'
            }
          },
          axisLabel: {
            fontSize: fontSize,
            color: '#8398BB'
          },
          axisTick: {
            show: false
          }
        },
        yAxis: {
          type: 'value',
          max: 19,
          min: 1,
          splitNumber: 4,
          inverse: true,
          splitLine: {
            lineStyle: {
              width: 1,
              type: 'dashed',
              color: '#8398BB'
            }
          },
          axisLine: {
            show: true,
            lineStyle: {
              width: 1,
              color: '#142746'
            }
          },
          axisLabel: {
            fontSize: fontSize,
            color: '#8398BB',
            formatter: '3A{value}'
          },
          axisTick: {
            show: false
          }
        },
        series: [
          {
            name: 'Max',
            type: 'line',
            smooth: true,
            data: this.xAxisnum,
            markPoint: {
              data: [
                {
                  symbolSize: '20',
                  silent: true,
                  yAxis: five,
                  xAxis: v
                }
              ],
              itemStyle: {
                color: '#ED5565'
              }
            }
          },
          {
            name: 'Min',
            type: 'line',
            smooth: true,
            data: this.yAxisnum
          },
          {
            name: 'Average',
            type: 'line',
            smooth: true,
            data: this.zAxisnum
          },
          {
            name: 'Subject Company',
            type: 'line',
            smooth: true,
            data: this.wAxisnums
          }
        ]
      }
      this.secondChart.setOption(option)
      this.secondChart.resize()
    },
    echarts4 (fontSize, pieFontSize, iconWidth) {
      if (this.fourChart !== null) {
        this.fourChart.dispose()
      }
      this.fourChart = this.$echarts.init(this.$refs.echarts4)
      const option = {
        tooltip: {
          formatter: (params) => {
            if (params.value > 1) {
              return  'Rating : ' + params['name'] + '<br/>' +  ' Number of Company: ' + params['value'] + '<br/>' +  ' %: ' + params['percent'] + '%'
            } else {
              return 'Rating : ' + params['name'] + '<br/>' + 'Number of Company : ' + params['value'] + '<br/>' + '% : ' + params['percent'] + '%'
            }
          }
        },
        series: [
          {
            // minAngle: 20,
            type: 'pie',
            clockwise: false,
            startAngle: 270,
            center: ['50%', '50%'],
            radius: [0, '70%'],
            label: {
              show: true,
              fontSize: pieFontSize,
              formatter: function (params) {
                if (params.data.isShow) {
                  return (
                    '{per|' +
                    params.percent +
                    '%} \n {b|' +
                    params.name +
                    '} {nameStyle| }'
                  )
                } else {
                  return (
                    '{per|' + params.percent + '%} \n {b|' + params.name + '}'
                  )
                }
              },
              rich: {
                nameStyle: {
                  width: iconWidth,
                  height: iconWidth,
                  backgroundColor: {
                    image: this.start1
                  }
                },
                per: {
                  color: '#ED5565',
                  fontSize: pieFontSize
                }
              }
            },
            labelLine: {
              length: 5,
              length2: 30
            },
            itemStyle: {
              label: {
                show: true,
                formatter: '{b}:{c}\n({d}%)'
              },
              color: function (params) {
                const colorList = [
                  '#138546',
                  '#01B152',
                  '#87C547',
                  '#FFDE2A',
                  '#F79024',
                  '#EE2424'
                ]
                return colorList[params.dataIndex]
              },
              borderWidth: 0,
              labelLine: { show: true }
            },
            data: this.List
          }
        ]
      }
      this.fourChart.setOption(option)
      this.fourChart.resize()
    },
    resizeFn () {
      if (document.body.clientWidth > 1664) {
        this.iconWidth = 16
        this.iconWidth1 = 14
        this.fontSize = 14
        this.pieFontSize = 14
      } else {
        this.iconWidth = 10
        this.iconWidth1 = 8
        this.fontSize = 14
        this.pieFontSize = 14
      }
      this.echarts1(this.fontSize)
      this.echarts2(this.fontSize)
      this.echarts4(this.fontSize, this.pieFontSize, this.iconWidth1)
    }
  }
}
</script>

<style scoped>
.title {
  border: 1px solid #e9ecf0;
}
.mt-5{
  margin-top:5px;
}
.lengedBoxItems:nth-child(4) {
  margin-right: 0;
}
::v-deep.el-table--scrollable-x ::-webkit-scrollbar {
  display: none;
}
.el-table ::v-deep .cellBg{
  background-color:#ED5565 !important;
  color:#ffffff
}
.cellBg .active{
  color: #ffffff !important;
}
/* 1920 */
@media (min-width: 1665px) {
  .el-table-filter {
    max-height: 350px;
    overflow: auto;
  }
  ::v-deep.el-table th{
    height: 40px;
  }
  ::v-deep .el-table__row td{
    height: 30px;
    font-size: 12px !important;
  }
  .rateEchartItems1 {
    width: 50%;
    height: 27.8571429rem;
    margin: 0 auto;
  }
  .rateEchartItems2 {
    width: 50%;
    height: 27.8571429rem;
    margin: 0 auto;
  }
  .rateEchartItems3 {
    width: 90%;
        height: 33.57143rem;
        margin: 0px 20px 0px auto;
        overflow-y: auto;
  }

  .rateEchartItems4 {
    width: 50%;
    height: 27.8571429rem;
    margin: 20px auto 40px auto;
  }
  .lengedBox {
    top: 120px;
    right: 70px;
    width: 360px;
  }
  .lengedBarBox {
    top: 58px;
    right: 100px;
  }
  .lengedLineBox1 {
    top: 118x;
    right: 95px;
  }
  .lengedLineBox {
    top: 90px;
    right: 125px;
  }

  .rateIndustrySelect ::v-deep .el-input__inner {
    height: 38px;
    padding-left: 20px;
    border-radius: 20px;
  }
  .rateIndustrySelect ::v-deep .el-input__suffix {
    right: 15px;
  }
  .rateIndustrySelect ::v-deep .el-input.is-focus .el-input__inner {
    border-radius: 20px;
  }
  .rateEchartItems:nth-child(odd) .rateEchartSelectInfo {
    padding-right: 116px;
  }
  .rateEchartItems:nth-child(even) .rateEchartSelectInfo {
    padding-right: 125px;
  }
  .rateEchartSelect {
    margin-bottom: 5px;
  }
  .rateEchartSelect ::v-deep .el-input__inner {
    width: 160px;
    height: 30px;
    line-height: 30px;
    padding: 0 10px !important;
    border-radius: 8px;
  }
  .rateEchartSelect ::v-deep .el-input__suffix {
    right: 10px;
  }
  .rateEchartSelect ::v-deep .el-input__icon {
    width: auto !important;
    line-height: 30px;
  }
  .rateEchartSelect ::v-deep .el-select + .el-select {
    margin-left: 20px;
  }
}
/* 1280*/
@media (max-width: 1664px) {
   .el-table-filter {
    max-height: 250px;
    overflow: auto;
 }
    ::v-deep.el-table th{
    height: 30px;
  }
  ::v-deep .el-table__row td{
    height: 26px;
  }
  .flex_center_between_box{
    flex-wrap: wrap;
  }
  /* .m-t{
     margin-top: 45px;
  } */
  .rateEchartItems1 {
    width: 100%;
    height: 27.8571429rem !important;
    margin: 0 auto;
  }

  .rateEchartItems2 {
    width: 100%;
    height: 27.8571429rem !important;
    margin: 0 auto;
  }

  .rateEchartItems3 {
    width: 100%;
    height: 27.8571429rem !important;
    margin: 14px auto 0 auto;
  }

  /* .rateEchartItems4 {
    width: 386px;
    height: 238px;
    margin: 34px auto 0 auto;
  } */
  .rateEchartItems4 {
    width: 100%;
    height: 27.8571429rem !important;
    margin: 14px auto 0 auto;
    /* padding-top: 60px;
    padding-left: 62px; */
  }
  .lengedBox {
    top: 78px;
    right: 46px;
    width: 295px;
  }
  .lengedBarBox {
    top: 36px;
    right: 66px;
  }
  .lengedLineBox1 {
    top: 76px;
    right: 55px;
  }
  .lengedLineBox {
    top: 56px;
    right: 55px;
  }
  .rateIndustrySelect ::v-deep .el-input__inner {
    height: 26px;
    padding-left: 14px;
    border-radius: 14px;
  }
  .rateIndustrySelect ::v-deep .el-input__suffix {
    right: 10px;
  }
  .rateIndustrySelect ::v-deep .el-select__caret {
    line-height: 26px;
  }
  .rateIndustrySelect ::v-deep .el-input.is-focus .el-input__inner {
    border-radius: 14px;
  }

  .rateEchartItems:nth-child(odd) .rateEchartSelectInfo {
    padding-right: 77px;
  }
  .rateEchartItems:nth-child(even) .rateEchartSelectInfo {
    padding-right: 66px;
  }
  .rateEchartSelect {
    margin-bottom: 4px;
  }
  .rateEchartSelect ::v-deep .el-input__inner {
    height: 20px;
    line-height: 20px;
    padding: 0 6px !important;
    border-radius: 5px;
  }
  .rateEchartSelect ::v-deep .el-input__suffix {
    right: 6px;
  }
  .rateEchartSelect ::v-deep .el-input__icon {
    width: auto !important;
    line-height: 20px;
  }
  .rateEchartSelectTitle {
    margin-right: 6px;
    font-size: 12px;
    color: #142746;
  }
  .rateEchartSelect ::v-deep .el-select + .el-select {
    margin-left: 14px;
  }

}
.rate_scale_table{
    height: auto;
    overflow: auto;
  }
  .rateEchartItems3 /deep/ .el-table tr{
  height: 2.85714286rem !important;
}
/deep/ .el-table .cell{
  padding-left: 10px !important;
}
</style>
