<template>
  <div class="tableInfoBox">
    <p class="title">{{ title }}</p>
    <el-table
      stripe
      :show-header="false"
      :data="tableData"
      :cell-class-name="changeColor"
      :cell-style="tableCellstyle"
      style="width:100%"
    >
      <el-table-column
        v-for="(item,index) in colData"
        :key="index"
        show-overflow-tooltip
        :prop="item.prop"
        :align="item.align"
        
      />

    </el-table>
  </div>
</template>
<script>
export default {
  props: {
    tableData: {
      type: Array,
      default: function () {
        return []
      }
    },
    colData: {
      type: Array,
      default: function () {
        return []
      }
    },
    title: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
    }
  },
  methods: {
    tableCellstyle ({ row, column, rowIndex, columnIndex }) {
      if (column.property) {
        // if (column.property == "indicators") {
        //   return column.width = 100
        // }
        // if (column.property == "rating3a") {
        //   return column.width = 50
        // }
        // if (column.property == "name" && row.name === '红色') {
        //   return column.width = 100
        // } else if (column.property == "name" && row.name === '蓝色') {
        //   return column.width = 100
        // } else if (column.property == "percentage" && row.name === '红色') {
        //   return column.width = 220
        // } else if (column.property == "percentage" && row.name === '蓝色') {
        //   return column.width = 220
        // } else {
        //   return 'color: #000'
        // }
      }
    },
    changeColor ({ row }) {
      if (row.name === 'Red') {
        return 'colorRed'
      } else if (row.name === 'Blue') {
        return 'colorBlue'
      } else {
        return 'colorBlank'
      }
    }
  }
}
</script>
<style scoped>
/* 1920 */
@media (min-width: 1665px) {
  ::v-deep .colorRed {
    color: #ed5565;
  }
  ::v-deep .colorBlue {
    color: #4885fa;
  }
  ::v-deep .colorBlank {
    color: #000000;
  }
  .tableInfoBox {
    border-radius: 8px;
    /* border: 1px solid #e9ecf0; */
    overflow: hidden;
  }
  .title {
    width: 100%;
    min-height: 40px;
    /* height: 40px; */
    font-family: 'DMSans Bold';
    text-align: center;
    /* line-height: 40px; */
    /* padding-left: 10px; */
    font-size: 1.28571429rem;
color: #1A2332;
    /* color: #ffffff; */
    /* letter-spacing: -0.5px; */
    /* background: #7e96c6; */
  }
  .tableInfoBox ::v-deep .el-table {
    border-radius: 0;
  }
  .tableInfoBox ::v-deep td {
    height: 40px;
    line-height: 40px;
    font-size: 14px;
  }
  .tableInfoBox ::v-deep .el-table tr td .cell {
    /* padding-left: 10px !important;
    padding-right: 10px !important; */
  }
}
/* 1280*/
@media (max-width: 1664px) {
  ::v-deep .colorRed {
    color: #ed5565;
  }
  ::v-deep .colorBlue {
    color: #4885fa;
  }
 ::v-deep .colorBlank {
    color: #000000;
  }
  .tableInfoBox {
    border-radius: 5px;
    /* border: 1px solid #e9ecf0; */
    overflow: hidden;
  }
  .title {
    width: 100%;
    min-height: 40px;
    /* height: 40px; */
    font-family: 'DMSans Bold';
    text-align: center;
    /* line-height: 40px; */
    /* padding-left: 10px; */
    font-size: 1.28571429rem;
color: #1A2332;
    /* color: #ffffff; */
    /* letter-spacing: -0.5px; */
    /* background: #7e96c6; */
  }
  .tableInfoBox ::v-deep .el-table {
    border-radius: 0;
  }
  .tableInfoBox ::v-deep td {
    height: 26px;
    line-height: 4026pxpx;
    font-size: 12px;
  }
  .tableInfoBox ::v-deep .el-table tr td .cell {
    padding-left: 6px !important;
    padding-right: 6px !important;
  }
}
.tableInfoBox ::v-deep tr {
  height: 5rem !important;
  }

  /deep/ .el-table .colorBlue .cell{
    color: #1290C9 !important;
  }
  /deep/ .el-table .colorRed .cell{
    color: #FF6A6A !important;
  }
</style>
