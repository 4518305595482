<template>
  <div :class="{systemDoorIndustryContainer:isShowTableRadius,tabChildList:isChildTab}">
    <div class="TabTitle" :class="{ TabTitleBorder: isShowBorder,TabTitleNo:ListData.length ==2 }">
      <ul class="flexBox">
        <li
          v-for="(item, index) in ListData"
          :key="index"
          class="TabTitleList flexCenter"
          :class="{ active1: activeIndex == index,beforeLine: activeIndex-1 == index, disabled: disabled }"
          :style="{ width: 'calc(100% / '+ ListData.length +')' }"
          @click="tabChange(index,item)"
        >
          {{ item.name }} <span v-if="item.tabvalue" class="tableTitle">{{ item.tabvalue }}</span>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    // eslint-disable-next-line vue/prop-name-casing
    ListData: {
      type: Array,
      default: function () {
        return []
      }
    },
    activeIndex: {
      type: Number,
      default: 0
    },
    isShowBorder: {
      type: Boolean,
      default: false
    },
    isShowTableRadius: {
      type: Boolean,
      default: false
    },
    isChildTab: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    tabChange (index, item, evenet) {
      this.$emit('tabChange', index, item)
    }
  }
}
</script>
<style scoped>
.tableTitle{
  color: red;
  margin-left: 10px;
}
.TabTitleList.disabled {
    color: #ffffff;
    background-color: #cccc;
  }
/* 1920 */
@media (min-width: 1665px) {

   .systemDoorIndustryContainer {
    position: relative;
  }
  .systemDoorIndustryContainer::after,
  .systemDoorIndustryContainer::before {
    content: "";
    position: absolute;
    top: 70px;
    width: 1px;
    height: 25px;
    background-color: #8398bb;
  }
  .systemDoorIndustryContainer::after {
    right: 0;
  }
  .systemDoorIndustryContainer::before {
    left: 0;
  }
  .TabTitle {
    width: 100%;
    height: 70px;
  }
  .TabTitleList {
    position: relative;
    height: 70px;
    /* line-height: 70px; */
    cursor: pointer;
    padding-left: 2px;
    font-size: 20px;
    color: #142746;
    text-align: center;
    background-color: #d5dbe5;
  }
  .pageEnglish .TabTitleList{
      font-size: 18px;
  }
  .TabTitle .active1 {
    color: #ffffff;
    /* background-color: #7e96c6 !important; */
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }
  .TabTitleList:not(:last-child)::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 0;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 1px;
    height: 20px;
    background-color: #8398bb;
  }

  .beforeLine:not(:last-child)::after{
    right: -1px;
  }
  .TabTitleBorder {
    border: 1px solid #8398bb;
    border-bottom: 0;
    border-radius: 24px 24px 0 0;
    overflow: hidden;
  }
  .TabTitleNo .TabTitleList::after {
    display: none;
  }

  .tabChildList ::v-deep .TabTitleBorder{
    border-radius: 0;
  }
  .tabChildLeft ::v-deep .TabTitle  .active1{
    border-top-left-radius: 0;
  }
  .tabChildRight ::v-deep .TabTitle  .active1{
    border-top-right-radius: 0;
  }
}
/* 1280*/
@media (max-width: 1664px) {
  .systemDoorIndustryContainer {
    position: relative;
  }
  .systemDoorIndustryContainer::after,
  .systemDoorIndustryContainer::before {
    content: "";
    position: absolute;
    top: 46px;
    width: 1px;
    height: 20px;
    background-color: #8398bb;
  }
  .systemDoorIndustryContainer::after {
    right: 0;
  }
  .systemDoorIndustryContainer::before {
    left: 0;
  }
  .TabTitle {
    width: 100%;
    height: 47px;
  }
  .TabTitleList{
    position: relative;
    height: 47px;
    /* line-height: 47px; */
    cursor: pointer;
    padding-left: 2px;
    font-size: 14px;
    color: #142746;
    text-align: center;
    background-color: #d5dbe5;
  }
  .pageEnglish .TabTitleList{
      font-size: 12px;
  }
  .TabTitle .active1 {
    color: #ffffff;
    /* background-color: #7e96c6 !important; */
    border-top-left-radius: 14px;
    border-top-right-radius: 14px;
  }

  .TabTitleList:not(:last-child)::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 0;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 1px;
    height: 14px;
    background-color: #8398bb;
  }
  .beforeLine:not(:last-child)::after{
    right: -1px;
  }
  .TabTitleBorder {
    border: 1px solid #8398bb;
    border-bottom: 0;
    border-radius: 16px 16px 0 0;
    overflow: hidden;
  }
  .TabTitleNo .TabTitleList::after {
    display: none;
  }

  .tabChildList ::v-deep .TabTitleBorder{
    border-radius: 0;
  }
  .tabChildLeft ::v-deep .TabTitle  .active1{
    border-top-left-radius: 0;
  }
  .tabChildRight ::v-deep .TabTitle  .active1{
    border-top-right-radius: 0;
  }
}
</style>
