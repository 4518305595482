<template>
  <div id="defaultId1" class="company_detail">
    <gui_card id="ge6845" class="uc1096 pb20" v-if="tableDatatwo.length > 0" style="margin-bottom: 10px;" label=""
      :isShowLabel="false">
      <h2 class="operations-head flex_center_between_box margin-bottom20" >
        <span class="head-titel detail_title_20">Rating Quantity Statistics</span>

      </h2>
      <el-table :data="tableDatatwo" style="width: 100%" empty-text="No Data"
        class=" table_height_30 table_font_size_14 table_contentHeight_40">
        <el-table-column label="" prop="head" style="height: 58px">
          <template slot-scope="scope">
            <span class="table-content">{{ scope.row.head }}</span>
          </template>
        </el-table-column>

        <el-table-column label="Average" prop="average" style="height: 58px" align="right">
          <template slot-scope="scope">
            <span class="table-content" v-if="scope.row.average !='null'">{{ scope.row.average }}</span>
          </template>
        </el-table-column>
        <el-table-column label="Best " prop="best" style="height: 58px" align="right">
          <template slot-scope="scope">
            <span class="table-content">{{ scope.row.best }}</span>
          </template>
        </el-table-column>
        <el-table-column label="Max10" align="right" prop="max10" style="height: 58px">
          <template slot-scope="scope">
            <span class="table-content">{{
              scope.row.max10
            }}</span>
          </template>
        </el-table-column>
        <el-table-column label="Mean" align="right" prop="mean" style="height: 58px">
          <template slot-scope="scope">
            <span class="table-content">{{
              scope.row.mean
            }}</span>
          </template>
        </el-table-column>
        <el-table-column label="Min10" align="right" prop="mean" style="height: 58px">
          <template slot-scope="scope">
            <span class="table-content">{{
              scope.row.min10
            }}</span>
          </template>
        </el-table-column>
        <el-table-column label="Worst" align="right" prop="mean" style="height: 58px">
          <template slot-scope="scope">
            <span class="table-content">{{
              scope.row.worst
            }}</span>
          </template>
        </el-table-column>
      </el-table>

    </gui_card>
    <gui_card id="ge6845" class="uc1096 pb20" label="" :isShowLabel="false">
      <h2 class="operations-head flex_center_between_box">
        <span class="head-titel detail_title_20">Rating Analysis </span>
        <div class="operations-box">
          <el-select popper-class="common_select" ref="upSelect" v-click-outside="clickOutSide" v-model="TabModel"
            placeholder="" style="width:21.4285714rem" @change="tabChange">
            <el-option v-for="(item, index) in tabList" :key="index" :label="item.name" :value="item.value">
            </el-option>
          </el-select>

        </div>
      </h2>
      <div class="systemDoorContainer systemRateContainer" v-loading="UdmLoading">

        <!-- <Tab :list-data="tabList" :active-index.sync="tabActiveIndex" :is-show-border="true" @tabChange="tabChange" /> -->
        <div class="creditRateBox" :class="{ correctBox: tabActiveIndex == 5 }">
          <Industry v-if="tabActiveIndex == 0" ref="are" :industry-code="enterprise.industryCode" :five="five"
            :company-aaaid="companyAaaid" :company-name-en="enterprise.companyNameEn"
            :company-name="enterprise.companyName" :industry-code-type-en-parent="enterprise.industryCodeTypeEnParent"
            :industry-code-type-en="enterprise.industryCodeTypeEn" :financialAbstracts="financialAbstracts" />
          <Area v-if="tabActiveIndex == 1" ref="arearef" :industry-code="enterprise.industryCode" :five="five"
            :company-aaaid="companyAaaid" :province="ratingCredit.province" :province-en="ratingCredit.provinceEn" />
          <Nature v-if="tabActiveIndex == 2" ref="areare" :industry-code="enterprise.industryCode"
            :company-aaaid="companyAaaid" :five="five" :nature-type="ratingCredit.natureType"
            :nature-type-en="ratingCredit.nature_type_en" />

          <Scale v-if="tabActiveIndex == 3" ref="arear" :industry-code="enterprise.industryCode"
            :company-aaaid="companyAaaid" :enterprise="enterprise" :five="five" />

          <History v-if="tabActiveIndex == 4" ref="area" :industry-code="enterprise.industryCode"
            :company-aaaid="companyAaaid" :copy-company="enterprise.companyName"
            :company-name-en="enterprise.companyNameEn" />

        </div>
      </div>
    </gui_card>
  </div>
</template>
<script>
import {
  enterpriseOverview,
  financialRatios,
  getMarketAnalysisQuantity
} from "@/api/companyApi";
import Tab from '@/components/tab'
import Industry from './components/industry.vue'
import gui_card from "@/components/gui_card/gui_card.vue";
import Area from './components/area.vue'
import Nature from './components/nature.vue'
import Scale from './components/scale.vue'
import History from './components/history.vue'

export default {
  components: {
    Tab,
    Industry,
    gui_card,
    Area,
    Nature,
    Scale,
    History,
    //   Correc
  },
  props: {






  },
  data() {
    return {
      tabActiveIndex: undefined,
      UdmLoading:false,
      TabModel: 0,
      five: '',
      tableDatatwo: [

        {
          name: 'Quantity',
          one: 10,
          two: 10,
          tree: 10,
          fore: 10,
          five: 10,
        },
        {
          name: 'Ratio',
          one: '10%',
          two: '10%',
          tree: '10%',
          fore: '10%',
          five: '10%',
        },
      ],
      companyAaaid: this.$route.query.id3a,
      enterprise: {
        // "companyName": "爱普香料集团股份有限公司",
        // "companyNameEn": "Apple Flavor&Fragrance Group Co., Ltd.",
        // "regOrgProvince": "上海市",
        // "regOrgDistrictEn": "Shanghai,Jiading District",



        // "enterpriseNature": "公众公司",
        // "establish": "29",
        // "companyType": "股份有限公司(上市、自然人投资或控股)",
        // "companyTypeEn": "Joint Stock Company (Listed, Investment or Holding by Natural Persons)",

        // "industryCode": "2684",

        // "industryCodeTypeCn": "香料、香精制造",
        // "industryCodeTypeEn": "Spice and flavor manufacturing",
        // "industryCodeTypeEnParent": null,

      },
      financialAbstracts: {
        // "totaloperatereve": "2783170095",
        // "salesGrowthRate": "-0.1309",
        // "jinglirun": "111392485",
        // "jinglirunGrowthRate": "-0.1063",
        // "sumasset": "3820461507",
        // "totalGrowthGrowthRate": "-0.0129",
        // "sumlasset": "2269816422",
        // "currentRatio": "6.2437",
        // "sumliab": "431570268",
        // "sumliabGrowthRate": "-0.2210"
      },
      ratingCredit: {}
    }
  },
  computed: {
    tabList() {
      const list = [
        {
          name: 'Industy Analysis',
          value: 0
        },
        {
          name: 'Geographic Analysis',
          value: 1
        },
        {
          name: 'Company Type Analysis',
          value: 2
        },
        {
          name: 'Biz Scale Analysis',
          value: 3
        },
        {
          name: 'History Analysis',
          value: 4
        },

      ]
      return list
    }
  },
  created() {

    this.enterpriseOverviewList()
    this.getgetMarketAnalysisQuantity()
  },
  methods: {
    clickOutSide() {
      if (this.$refs.upSelect) {
        this.$refs.upSelect.visible = false
      }

    },
    getgetMarketAnalysisQuantity() {
      const params = new URLSearchParams();
      params.append('aaaId', this.$route.query.id3a);
      getMarketAnalysisQuantity(params).then(res => {
        if (res.code == 200) {
          this.tableDatatwo = res.data
        }
      })
    },
    enterpriseOverviewList() {
      enterpriseOverview({
        keyid: this.$route.query.id3a
      }).then(res => {
        if (res.code == 200) {
          this.enterprise = res.data.enterprise
          this.ratingCredit = res.data.financial
          this.ratingCredit.province = res.data.enterprise?.regOrgProvince
          this.ratingCredit.provinceEn = res.data.enterprise?.regOrgProvinceCn
          this.ratingCredit.natureType = res.data?.creditratings == null ? [] : res.data?.creditratings[0]?.natureType
          this.ratingCredit.nature_type_en = res.data?.creditratings == null ? [] : res.data?.creditratings[0]?.natureTypeEn

          this.five = res.data.financial.ratingGrade == null ? '3A7' : res.data.financial.ratingGrade

          this.ratingCredit.risk_level_cn = res.data.financial.creditRiskCodeDescCn
          this.ratingCredit.risk_level_en = res.data.financial.creditRiskCodeDescEn == null ? 'Average' : res.data.financial.creditRiskCodeDescEn
          this.industryCode = res.data.enterprise.industryCode
          this.industrycocode = res.data.enterprise.industrycocode

        }
        financialRatios({
          keyid: this.$route.query.id3a,
          type: 0

        }).then(res1 => {
          if (res1.code == 200) {
            this.financialAbstracts = res1.data.financialAbstracts
            this.tabActiveIndex = 0
          }

        })

      })

    },
    tabChange() {
      this.tabActiveIndex = this.TabModel

    },

  }
}
</script>

<style scoped>
.importDetail-head {
  display: flex;
}

.head-titel {
  font-family: 'DMSans Bold';
  font-weight: 400;
  font-size: 2.142857142857143rem;
  display: flex;
  align-items: center;
}



.operations-head {
  font-size: 2.142857142857143rem;
  /* font-family: "Arial Bold"; */
  font-family: 'DMSans Bold';
  color: #1a2332;
  text-align: left;
  font-style: normal;
  display: flex;
  justify-content: space-between;
}

.flex_center_between_box {
  display: flex;
  justify-content: space-between;
}

/deep/ .el-table .cell {
  font-size: 1.4285714285714286rem;
  color: #1a2332;
  /* font-family: 'DMSans Bold'; */
  padding-left: 1.4285714285714286rem !important;
}

/deep/ .highlight-cell {
  background-color: #f2f5f9 !important;
}

/deep/ .el-table th.el-table__cell {
  background-color: #f2f5f9 !important;
  font-family: 'DMSans Bold';
}

#cb02bf {
  padding-bottom: 1.4285714285714286rem;
}

.contclass {
  font-size: 1.4285714285714286rem;
  color: #1290c9;
  font-style: normal;
}







.uUIRzUi {
  padding: 2.142857142857143rem 2.857142857142857rem;
}

.systemDoorContainer {

  margin-top: 1.4285714285714286rem;
}

.head-titel {
  font-family: 'DMSans Bold';
  font-weight: 400;
  font-size: 2.142857142857143rem;
  display: flex;
  align-items: center;
}
</style>
