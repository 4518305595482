<template>
  <div class="rateNoSelect">
    <!-- 验证评级——性质分析 -->
    <div class="rateIndustryEchart flexBox">
      <div class="rateEchartItems">
        <div class="rateEchartTips flexCenter">
          <span class="title">Analysis based on nature of companies in subject's industry</span>
        </div>
        <ul class="lengedBox flexCenter lengedBarBox">
          <li class="flexCenter flex_center_start_box lengedBoxItems m-lf lengedBoxItemsText" >
            <span class="redkuai" > </span>
            <span class="">Subject Company</span>
          </li>
        </ul>
        <div class="flex_center_between_box">
        <div
          ref="echarts1"
          class="rateEchartItems1"
        />
        <div class="rateEchartInfo">
          <Table
            :title="'Probability of the subjects rating in its industry:'"
            :table-data="echartsList1"
            :col-data="colData3"
          />
        </div>
        </div>
      </div>
      <div class="rateEchartItems">
        <div class="rateEchartTips flexCenter">
          <span class="title">Analysis based on nature of companies in subject's industry</span>
        </div>
        <ul class="lengedBox flexCenter lengedBarBox">
          <li class="flexCenter flex_center_start_box lengedBoxItems m-lf lengedBoxItemsText" >
            <span class="redkuai" > </span>
            <span class="">Subject Company</span>
          </li>
        </ul>
        <div class="flex_center_between_box">
        <div
          ref="echarts2"
          class="rateEchartItems2"
        />
        <div class="rateEchartInfo">
          <Table
            :title="'Subject rating position in its company type:'"
            :table-data="echartsList2"
            :col-data="colData1"
          />
        </div>
        </div>
      </div>
    </div>
    <div class="rateIndustryEchart flexBox" style="margin-top: 10px;">
      <div class="rateEchartItems">
        <div class="rateEchartTips flexCenter">
          <span class="title"> Historical development of subject's rating in its company type</span>
        </div>
        <ul class="lengedBox flexCenter lengedBarBox">
          <li class="flexCenter flex_center_start_box lengedBoxItems m-lf lengedBoxItemsText" >
            <span class="redkuai" > </span>
            <span class="">Subject Company</span>
          </li>
        </ul>
        <div class="flex_center_between_box">
        <div
          ref="echarts3"
          class="rateEchartItems3"
        />
        <div class="rateEchartInfo" style="width: 35%;">
          <Table
            :title="'Probability of the subject rating in its industry:'"
            :table-data="echartsList3"
            :col-data="colData2"
          />
        </div>
        </div>
      </div>
      <div class="rateEchartItems">
        <div class="rateEchartTips flexCenter margin-bottom20">
          <span class="title">Rating statistics of companies in the same industry</span>
        </div>
        <div class="flex_center_between_box">
        <div
          ref="echarts4"
          class="rateEchartItems4"
        >
          <!-- <img src="../../../assets/images/system/credit/echart4.png"> -->
        </div>
        <div class="rateEchartInfo" style="width: 35%;">
          <Table
            :title="'Probability of the subjects rating in its industry:'"
            :table-data="echartsList3"
            :col-data="colData2"
          />
        </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Table from '@/components/table'
import { ratingNature } from "@/api/companyApi";
import { mapGetters } from 'vuex'
export default {
  components: {
    Table: Table
  },
  props: {
    industryCode: {
      type: String,
      default: ''
    },
    companyAaaid: {
      type: String,
      default: ''
    },
    natureType: {
      type: String,
      default: ''
    },
    natureTypeEn: {
      type: String,
      default: ''
    },
    five: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      start: 'image://' + require('@/assets/images/system/credit/start.png'),
      start1: require('@/assets/images/system/credit/start.png'),
      iconWidth: 16,
      iconWidth1: 14,
      fontSize: 14,
      pieFontSize: 14,
      year: [],
      years: [],
      targetCompanyMaxYear: [],
      echartsList1: [],
      echartsList2: [],
      echartsList3: [],
      colData1: [
        {
          prop: 'indicators',
          align: 'left'
        },
        {
          prop: 'rating3a',
          align: 'right'
        },
        {
          prop: 'num',
          align: 'right'
        },
        {
          prop: 'value',
          align: 'right'
        }
      ],
      colData2: [
        {
          prop: 'indicators',
          align: 'left'
        },
        {
          prop: 'value',
          align: 'right'
        }
      ],
      colData3: [
        {
          prop: 'indicators',
          align: 'left'
        },
        {
          prop: 'rating3a',
          align: 'right'
        },
        {
          prop: 'rating',
          align: 'right'
        },
        {
          prop: 'value',
          align: 'right'
        }
      ],
      xAxisnum: [],
      yAxisnum: [],
      zAxisnum: [],
      natureTypes: {},
      naturesEn: [],
      conclusion: '',
      conclusionEn: '',
      firstChart: null,
      secondChart: null,
      thirdChart: null,
      fourChart: null,
      lang:'en'
    }
  },
  computed: {
    // ...mapGetters({
    //   lang: 'lang'
    // })
  },
  watch: {
    lang () {
      this.echarts1(this.fontSize)
      this.echarts2(this.fontSize, this.iconWidth)
      this.echartLang()
      if (this.lang === 'en') {
        this.colData3[0].prop = 'indicatorsEn'
      } else {
        this.colData3[0].prop = 'indicators'
      }
    }
  },
  mounted () {
    window.addEventListener('resize', this.resizeFn)
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.resizeFn)
  },
  created () {
    this.naturex()
  },
  methods: {
    naturex () {
      const paramsThree = {
        industryCode: this.industryCode,
        aaaId: this.companyAaaid,
        enterpriseNature: this.natureType,
        country:this.$route.query.companyCountry
      }
      this.nature_type = []
      this.num = []
      this.List = []
      this.years = []
      this.year = []
      this.natures = []
      this.xAxisnum = []
      this.yAxisnum = []
      this.zAxisnum = []
      this.xAxisnums = []
      this.yAxisnums = []
      this.zAxisnums = []
      this.wAxisnums = []
      this.targetCompanyMaxYear = []
      if (this.lang === 'en') {
        this.colData1[0] = { prop: 'indicatorsEn', align: 'left' }
        this.colData2[0] = { prop: 'indicatorsEn', align: 'left' }
      } else {
        this.colData1[0] = { prop: 'indicators', align: 'left' }
        this.colData2[0] = { prop: 'indicators', align: 'left' }
      }
      if (this.lang === 'en') {
        this.colData3[0].prop = 'indicatorsEn'
      } else {
        this.colData3[0].prop = 'indicators'
      }
      ratingNature(paramsThree).then(res => {
        this.natureTypes = {}
        const natureTypecn = []
        const natureTypeen = []
        for (var item of res.data.charts1) {
          natureTypecn.push(item.nature_type)
          natureTypeen.push(item.nature_type_en)
          this.num.push(item.num)
        }
        this.natureTypes.cn = natureTypecn
        this.natureTypes.en = natureTypeen
        const echart2Date = {}
        this.naturesEn = []
        res.data.charts2.map(item => {
          if (!echart2Date[item.nature_type]) {
            echart2Date[item.nature_type] = {}
            this.naturesEn.push(item.nature_type_en)
          }
          if (item.index_type === '最差值') {
            echart2Date[item.nature_type].min = item.rating_3a.replace('3A', '') ? item.rating_3a.replace('3A', '') : ''
          } else if (item.index_type === '最好值') {
            echart2Date[item.nature_type].max = item.rating_3a.replace('3A', '') ? item.rating_3a.replace('3A', '') : ''
          } else if (item.index_type === '平均值') {
            echart2Date[item.nature_type].avg = item.rating_3a.replace('3A', '') ? item.rating_3a.replace('3A', '') : ''
          }
          // else if (item.index_type === '目标公司') {
          //   // echart3Date[item.year].targe = item?.rating_grade_3a?.replace('3A', '') ? item?.rating_grade_3a?.replace('3A', '') : ''
          //   echart3Date[item.year].targe = item?.ratingGrade?.replace('3A', '') ? item?.ratingGrade?.replace('3A', '') : ''
          //   // 新字段

          // }
        })
        for (var key in echart2Date) {
          const d = echart2Date[key]
          this.natures.push(key)
          this.xAxisnum.push(d.max)
          this.yAxisnum.push(d.min)
          this.zAxisnum.push(d.avg)
        }
        const echart3Date = {}
        res.data.charts3.map(item => {
          if (!echart3Date[item.year]) {
            echart3Date[item.year] = {}
          }
          if (item.index_type === '最差值') {
            echart3Date[item.year].min = item.rating_3a?.replace('3A', '') ? item.rating_3a?.replace('3A', '') : ''
            // echart3Date[item.year].min = item.rating_3a?.replace('3A', '') ? item.rating_3a?.replace('3A', '') : ''
          } else if (item.index_type === '最好值') {
            echart3Date[item.year].max = item.rating_3a?.replace('3A', '') ? item.rating_3a?.replace('3A', '') : ''
          } else if (item.index_type === '平均值') {
            echart3Date[item.year].avg = item.rating_3a?.replace('3A', '') ? item.rating_3a?.replace('3A', '') : ''
          } else if (item.index_type === '目标公司') {
            // echart3Date[item.year].targe = item?.rating_grade_3a?.replace('3A', '') ? item?.rating_grade_3a?.replace('3A', '') : ''
            echart3Date[item.year].targe = item?.ratingGrade?.replace('3A', '') ? item?.ratingGrade?.replace('3A', '') : ''
            // 新字段

          }
        })
        for (var i in echart3Date) {
          const x = echart3Date[i]
          this.year.push(i || '')
          this.xAxisnums.push(x.max)
          this.yAxisnums.push(x.min)
          this.zAxisnums.push(x.avg)
          this.wAxisnums.push(x.targe)
        }
        this.newYear = this.year.sort() ? this.year.sort() : ''
        this.echartsList1 = res.data.table1.map(item => {
          if (item.indicators === '目标公司') {
            item.value = ''
            return item
          } else {
            item.value = '+' + (item.value * 100).toFixed(2) + '%'
            // item.value = '+' + (item.value * 100).toFixed(2)
            return item
          }
        })
        this.echartsList2 = res.data.table2.map(item => {
          if (item.indicators === '目标公司') {
            item.value = ''
            return item
          } else {
            item.value = '+' + (item.value * 100).toFixed(2) + '%'
            // item.value = '+' + (item.value * 100).toFixed(2)
            return item
          }
        
      
        })
        this.echartsList2.push('')
        res.data.ratingPies.forEach(item => {
          this.List.push({ name: item.indexType, value: item.num })
        })
        this.targetCompanyMaxYear = res.data.targetCompanyMaxYear
        const rate = this.five.replace('3A', '') ? this.five.replace('3A', '') : ''
        if (rate >= 1 && rate <= 6) {
          this.conclusion = '表现良好'
          this.conclusionEn = 'Good'
        } else if (rate >= 7 && rate <= 9) {
          this.conclusion = '表现一般'
          this.conclusionEn = 'Normal'
        } else if (rate >= 10 && rate <= 12) {
          this.conclusion = '表现较差'
          this.conclusionEn = 'Weak'
        } else if (rate >= 13 && rate <= 19) {
          this.conclusion = '表现极差'
          this.conclusionEn = 'Alert'
        } else {
          this.conclusion = ''
          this.conclusionEn = ''
        }
        this.$nextTick(() => {
          this.resizeFn()
          this.echartLang()
        })
      })
    },
    echarts1 (fontSize) {
      if (this.firstChart !== null) {
        this.firstChart.dispose()
      }
      this.firstChart = this.$echarts.init(this.$refs.echarts1)
    
      const types = this.lang === 'en' ? this.natureTypeEn : this.natureType
      const natureTypes = this.natureTypes[this.lang]
      const option = {
        color: ['#1290C9'],
        grid: {
          left: '0%',
          right: '5%',
          bottom: '-0.5%',
          top: '15%',
          containLabel: true
        },
        tooltip: {
          formatter: (params) => {
            if (params.name === types) {
              return  'Company Type: ' + params['name'] + '<br/>' + 'Number of Company: ' + params['data']
            } else {
              return  'Company Type: ' + params['name'] + '<br/>'  + 'Number of Company: ' + params['data']
            }
          }
        },
        xAxis: [
          {
            type: 'category',
            data: natureTypes,
            axisLine: {
              show: true,
              lineStyle: {
                width: 1,
                color: '#142746'
              }
            },
            axisTick: {
              show: false,
              alignWithLabel: true
            },
            axisLabel: {
              fontSize: fontSize,
              color: '#8398BB'
            }
          }
        ],
        yAxis: {
          type: 'value',
          splitLine: {
            lineStyle: {
              width: 1,
              type: 'dashed',
              color: '#8398BB'
            }
          },
          axisLine: {
            show: true,
            lineStyle: {
              width: 1,
              color: '#142746'
            }
          },
          axisLabel: {
            fontSize: fontSize,
            color: '#8398BB'
          },
          axisTick: {
            show: false
          }
        },
        series: [
          {
            type: 'bar',
            barWidth: '48%',
            label: {
              show: false
            },
            itemStyle: {
              color: function (params) {
                if (params.name === types) {
                  return '#ED5565'
                } else {
                  return params.color
                }
              }
            },
            data: this.num
          }
        ]
      }
      this.firstChart.setOption(option)
      this.firstChart.resize()
    },
    // echarts根据中英文显示legend文字
    echartLang () {
      var newOption = this.secondChart.getOption()
      var newOption2 = this.thirdChart.getOption()
      newOption.legend = { data: ['Best', 'Worst', 'Average'] }
      newOption.series[0].name = 'Best'
      newOption.series[1].name = 'Worst'
      newOption.series[2].name = 'Average'
      newOption2.legend = { data: ['Best', 'Worst', 'Average'] }
      newOption2.series[0].name = 'Best'
      newOption2.series[1].name = 'Worst'
      newOption2.series[2].name = 'Average'
      newOption2.series[3].name = 'Subject Company'
      this.secondChart.setOption(newOption)
      this.thirdChart.setOption(newOption2)
      if (this.lang === 'en') {
        this.colData1[0] = { prop: 'indicatorsEn', align: 'left' }
        this.colData2[0] = { prop: 'indicatorsEn', align: 'left' }
      } else {
        this.colData1[0] = { prop: 'indicators', align: 'left' }
        this.colData2[0] = { prop: 'indicators', align: 'left' }
      }
      const card = [
        {
          indicators: '企业性质',
          indicatorsEn: 'Nature of enterprise',
          value: this.lang === 'en' ? this.natureTypeEn : this.natureType
        },
        {
          indicators: '性质表现',
          indicatorsEn: 'Performance',
          value: this.lang === 'en' ? this.conclusionEn : this.conclusion
        }
      ]
      this.echartsList3 = [
        ...this.echartsList1.filter(i => i.indicators !== '目标公司'),
        ...card
      ]
    },
    echarts2 (fontSize, iconWidth) {
      if (this.secondChart !== null) {
        this.secondChart.dispose()
      }
      this.secondChart = this.$echarts.init(this.$refs.echarts2)
      const five = this.five.replace('3A', '') ? this.five.replace('3A', '') : ''
      const types = this.lang === 'en' ? this.natureTypeEn : this.natureType
      // , '目标公司'
      const option = {
        color: ['#81C97A', '#1A2332', '#1290C9', '#FF6A6A'],
        grid: {
          left: '0%',
          right: '3%',
          bottom: '0%',
          top: '12%',
          containLabel: true
        },
        legend: {
          right: '5%',
          top: '-2%',
          data: ['最高', '最低', '平均'],
          textStyle: {
            lineHeight: '26'
          }
        },
        tooltip: {
          // trigger: 'item',
          showDelay: 0,
          formatter: (params) => {
            if (params.value) {
              if (params.value.length > 1) {
                return 'Company Type: ' + params['name'] + '<br/>' + params.seriesName + ' : ' +
                  '3A' + params.value
              } else {
                return  'Company Type: ' + params['name'] + '<br/>' + params.seriesName + ' : ' +
                  '3A' + params.value
              }
            } else {
              if (params.componentType === 'markPoint') {
                return 'Subject Company: ' + params.data.xAxis + '<br/>' +  'Rating：' + '3A' + params.data.yAxis
              }
            }
          }
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: this.lang === 'en' ? this.naturesEn : this.natures,
          splitLine: {
            show: true,
            lineStyle: {
              width: 1,
              type: 'dashed',
              color: '#8398BB'
            }
          },
          axisLine: {
            show: true,
            lineStyle: {
              width: 1,
              color: '#142746'
            }
          },
          axisLabel: {
            fontSize: fontSize,
            color: '#8398BB'
          },
          axisTick: {
            show: false
          }
        },
        yAxis: {
          type: 'value',
          max: 19,
          min: 1,
          splitNumber: 4,
          inverse: true,
          splitLine: {
            lineStyle: {
              width: 1,
              type: 'dashed',
              color: '#8398BB'
            }
          },
          axisLine: {
            show: true,
            lineStyle: {
              width: 1,
              color: '#142746'
            }
          },
          axisLabel: {
            fontSize: fontSize,
            color: '#8398BB',
            formatter: '3A{value}'
          },
          axisTick: {
            show: false
          }
        },
        series: [
          {
            name: '最高',
            type: 'line',
            smooth: true,
            data: this.xAxisnum,
            markPoint: {
              data: [
                {
                  symbolSize: '20',
                  silent: true,
                  yAxis: five,
                  xAxis: types
                }
              ],
              itemStyle: {
                color: '#ED5565'
              }
            }
          },
          {
            name: '最低',
            type: 'line',
            smooth: true,
            data: this.yAxisnum
          },
          {
            name: '平均',
            type: 'line',
            smooth: true,
            data: this.zAxisnum
          }
        ]
      }
      this.secondChart.setOption(option)
      this.secondChart.resize()
    },
    echarts3 (fontSize) {
      if (this.thirdChart !== null) {
        this.thirdChart.dispose()
      }
      this.thirdChart = this.$echarts.init(this.$refs.echarts3)
      const data = this.newYear
      const option = {
        color: ['#81C97A', '#1A2332', '#1290C9', '#FF6A6A'],
        grid: {
          left: '0%',
          right: '5%',
          bottom: '0%',
          top: '10%',
          containLabel: true
        },
        legend: {
          top: '-1.5%',
          left: '35%',
          textStyle: {
            lineHeight: '26'
          },
          data: ['最高', '最低', '平均']
        },
        tooltip: {
          // trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          formatter: (params) => {
            if (params.value.length > 1) {
              return  'Year: ' + params['name'] + '<br/>' + params.seriesName + ' : ' +
                '3A' + params.value
            } else {
              return  'Year: ' + params['name'] + '<br/>' + params.seriesName + ' : ' +
                '3A' + params.value
            }
          }
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: data,
          splitLine: {
            show: true,
            lineStyle: {
              width: 1,
              type: 'dashed',
              color: '#8398BB'
            }
          },
          axisLine: {
            show: true,
            lineStyle: {
              width: 1,
              color: '#142746'
            }
          },
          axisLabel: {
            fontSize: fontSize,
            color: '#8398BB'
          },
          axisTick: {
            show: false
          }
        },
        yAxis: {
          type: 'value',
          max: 19,
          min: 1,
          splitNumber: 4,
          inverse: true,
          splitLine: {
            lineStyle: {
              width: 1,
              type: 'dashed',
              color: '#8398BB'
            }
          },
          axisLine: {
            show: true,
            lineStyle: {
              width: 1,
              color: '#142746'
            }
          },
          axisLabel: {
            fontSize: fontSize,
            color: '#8398BB',
            formatter: '3A{value}'
          },
          axisTick: {
            show: false
          }
        },
        series: [
          {
            name: '最高',
            type: 'line',
            smooth: true,
            data: this.xAxisnums
          },
          {
            name: '最低',
            type: 'line',
            smooth: true,
            data: this.yAxisnums
          },
          {
            name: '平均',
            type: 'line',
            smooth: true,
            data: this.zAxisnums
          },
          {
            name: '目标公司',
            type: 'line',
            smooth: true,
            data: this.wAxisnums
          }
        ]
      }
      this.thirdChart.setOption(option)
      this.thirdChart.resize()
    },
    echarts4 (fontSize, pieFontSize, iconWidth) {
      if (this.fourChart !== null) {
        this.fourChart.dispose()
      }
      this.fourChart = this.$echarts.init(this.$refs.echarts4)
      const option = {
        tooltip: {
          formatter: function (params) {
            if (params.value > 1) {
              return  'Rating: ' + params['name'] + '<br/>' + 'Number of Company: ' + params['value'] + '<br/>' + '%: ' + params['percent'] + '%'
            } else {
              return  'Rating: ' + params['name'] + '<br/>' +  'Number of Company: ' + params['value'] + '<br/>' +   '%: ' + params['percent'] + '%'
            }
          }
        },
        series: [
          {
            type: 'pie',
            // minAngle: 30,
            clockwise: false,
            startAngle: 270,
            center: ['50%', '50%'],
            radius: [0, '70%'],
            label: {
              show: true,
              fontSize: pieFontSize,
              formatter: function (params) {
                if (params.data.isShow === true) {
                  return (
                    '{per|' +
                    params.percent +
                    '%} \n {b|' +
                    params.name +
                    '} {nameStyle| }'
                  )
                } else {
                  return (
                    '{per|' + params.percent + '%} \n {b|' + params.name + '}'
                  )
                }
              },
              rich: {
                nameStyle: {
                  width: iconWidth,
                  height: iconWidth,
                  backgroundColor: {
                    image: this.start1
                  }
                },
                per: {
                  color: '#ED5565',
                  fontSize: pieFontSize
                }
              }
            },
            labelLine: {
              length: 5,
              length2: 30
            },
            itemStyle: {
              label: {
                show: true,
                formatter: '{b}:{c}\n({d}%)'
              },
              color: function (params) {
                const colorList = [
                  '#138546',
                  '#01B152',
                  '#87C547',
                  '#FFDE2A',
                  '#F79024',
                  '#EE2424'
                ]
                return colorList[params.dataIndex]
              },
              borderWidth: 0,
              labelLine: { show: true }
            },
            data: this.List
          }
        ]
      }
      this.fourChart.setOption(option)
      this.fourChart.resize()
    },
    resizeFn () {
      if (document.body.clientWidth > 1664) {
        this.iconWidth = 16
        this.iconWidth1 = 14
        this.fontSize = 14
        this.pieFontSize = 14
      } else {
        this.iconWidth = 10
        this.iconWidth1 = 18
        this.fontSize = 14
        this.pieFontSize = 14
      }
      this.echarts1(this.fontSize)
      this.echarts2(this.fontSize, this.iconWidth)
      this.echarts3(this.fontSize)
      this.echarts4(this.fontSize, this.pieFontSize, this.iconWidth1)
    }
  }
}
</script>
<style scoped>
.lengedBoxItems:nth-child(4) {
  margin-right: 0;
}
/* 1920 */
@media (min-width: 1665px) {
  /* .m-t{
    margin-top:65px;
  } */
  .rateEchartItems1 {
    width: 50%;
    height: 27.8571429rem;
    margin: 0 auto;
  }
  .rateEchartItems2 {
    width: 50%;
    height: 27.8571429rem;
    margin: 0 auto;
  }
  .rateEchartItems3 {
    width: 65%;
    height: 34.8571429rem;
    margin: 0 auto 0 auto;
  }

  .rateEchartItems4 {
    width: 65%;
    height: 34.8571429rem;
    margin: 0 auto 0 auto;
    /* padding-top: 80px;
    padding-left: 92px; */
  }
  .lengedBox {
    top: 120px;
    right: 70px;
    width: 360px;
  }
  .lengedBarBox {
    top: 58px;
    right: 100px;
  }
  .lengedLineBox1 {
    top: 118x;
    right: 95px;
  }
  .lengedLineBox {
    top: 58px;
    right: 95px;
  }
}
/* 1280*/
@media (max-width: 1664px) {
   /* .m-t{
    margin-top:45px;
  } */
  .mt-5{
     margin-top:5px;
  }
  .flex_center_between_box{
    flex-wrap: wrap;
  }
  /* .m-t{
     margin-top: 45px;
  } */
  .rateEchartItems1 {
    width: 100%;
    height: 27.8571429rem !important;
    margin: 0 auto;
  }

  .rateEchartItems2 {
    width: 100%;
    height: 27.8571429rem !important;
    margin: 0 auto;
  }

  .rateEchartItems3 {
    width: 100%;
    height: 27.8571429rem !important;
    margin: 14px auto 0 auto;
  }

  /* .rateEchartItems4 {
    width: 386px;
    height: 238px;
    margin: 34px auto 0 auto;
  } */
  .rateEchartItems4 {
    width: 100%;
    height: 27.8571429rem !important;
    margin: 14px auto 0 auto;
    /* padding-top: 60px;
    padding-left: 62px; */
  }
  .lengedBox {
    top: 78px;
    right: 46px;
    width: 295px;
  }
  .lengedBarBox {
    top: 36px;
    right: 66px;
  }
  .lengedLineBox1 {
    top: 76px;
    right: 55px;
  }
  .lengedLineBox {
    top: 36px;
    right: 55px;
  }
}
</style>
