<template>
  <div class="rateNoSelect">
    <!-- 验证评级——区域分析 -->

    <div class="rateIndustryEchart flexBox">
      <div class="rateEchartItems">
        <div class="rateEchartTips flexCenter">
          <span class="title">Subject's Industry Concentration Analysis</span>
        </div>
        <ul class="lengedBox flexCenter lengedBarBox">
          <li class="flexCenter flex_center_start_box lengedBoxItems m-lf lengedBoxItemsText">
            <span class="redkuai"> </span>
            <span class="">Subject Company</span>
          </li>
        </ul>
        <div class="flex_center_between_box">
          <div class="rateEchartItems1" style="height: auto; ">
            <div ref="echarts1" style="width: 98%; height: 100%" />
          </div>

          <div class="rateEchartInfo">
            <Table :title="'Geographic distribution in Subject\'\s industry：'" :table-data="echartsList"
              :col-data="colData3" />
          </div>
        </div>
      </div>
      <div class="rateEchartItems">
        <div class="rateEchartTips flexCenter">
          <span class="title">Geographic distribution of Subject's rating in its industry</span>
        </div>
        <ul class="lengedBox flexCenter lengedBarBox">
          <li class="flexCenter flex_center_start_box lengedBoxItems m-lf lengedBoxItemsText">
            <span class="redkuai"> </span>
            <span class="">Subject Company</span>
          </li>
        </ul>
        <div class="flex_center_between_box">
          <div class="rateEchartItems2" style="height: auto; ">
            
            <div ref="echarts2" style="width: 100%; height: 100%" />
          </div>
          <div class="rateEchartInfo">
            <Table :title="'Probability of the subject\'\s rating in its industry:'" :table-data="echartsList2"
              :col-data="colData1" />
          </div>
        </div>
      </div>
    </div>
    <div class="rateIndustryEchart flexBox" style="margin-top: 10px;">
      <div class="rateEchartItems">
        <div class="rateEchartTips flexCenter">
          <span class="title">Historical development of subject's rating in its location</span>
        </div>
        <ul class="lengedBox flexCenter lengedBarBox">
          <li class="flexCenter flex_center_start_box lengedBoxItems m-lf lengedBoxItemsText">
            <span class="redkuai"> </span>
            <span class="">Subject Company</span>
          </li>
        </ul>
        <div class="flex_center_between_box">
          <div class="rateEchartItems3" style="height: auto; ">
            <div ref="echarts3" style="width: 100%; height: 100%" />
          </div>

          <div class="rateEchartInfo" style="width: 35%;">
            <Table :title="'Probability of the subject\'\s rating in its industry:'" :table-data="echartsList4"
              :col-data="colData2" />
          </div>
        </div>
      </div>
      <div class="rateEchartItems">
        <div class="rateEchartTips flexCenter margin-bottom20" >
          
          <span class="title">Rating Distribution in Subject's Location</span>
        </div>
     
        <div class="flex_center_between_box">
          <div class="rateEchartItems4" style="height: auto; ">
            <div ref="echarts4" style="width: 100%; height: 100%" />
          </div>
            <!-- <img src="@/assets/images/system/credit/echart4.png"> -->
          <div class="rateEchartInfo" style="width: 35%;">
            <Table :title="'Probability of the subject\'\s rating in its industry:'" :table-data="echartsList3"
              :col-data="colData2" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import Table from '@/components/table'

import {
  ratingAreaNumTable
} from "@/api/companyApi";
export default {
  components: {
    Table: Table
  },
  computed: {
    // ...mapGetters({
    //   lang: 'lang'
    // })
  },
  props: {
    industryCode: {
      type: String,
      default: ''
    },
    companyAaaid: {
      type: String,
      default: ''
    },
    province: {
      type: String,
      default: ''
    },
    provinceEn: {
      type: String,
      default: ''
    },
    five: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      clientWidth: document.body.clientWidth,
      provinces: [],
      provincell: [],
      echartsList: [],
      rating3as: [],
      echart1Data: [],
      provincesEn: [],
      num: [],
      year: [],
      years: [],
      iconWidth: 16,
      iconWidth1: 14,
      lang: 'en',
      fontSize: 14,
      pieFontSize: 14,
      start: 'image://' + require('@/assets/images/system/credit/start.png'),
      start1: require('@/assets/images/system/credit/start.png'),
      echartsList2: [],
      echartsList3: [],
      echartsList4: [
        {
          name: '企业所在地',
          percentage: ''
        }
      ],
      colData1: [
        {
          prop: 'indexType',
          align: 'left'
        },
        {
          prop: 'rating3a',
          align: 'right'
        },
        {
          prop: 'num',
          align: 'right'
        },
        {
          prop: 'percentage',
          align: 'right'
        },
        {
          prop: '',
          align: 'right'
        }
      ],
      colData3: [
        {
          prop: 'indexTypeEn',
          align: 'left'
        },
        {
          prop: 'rating3a',
          align: 'right'
        },
        {
          prop: 'rating',
          align: 'right'
        },
        {
          prop: 'percentage',
          align: 'right'
        }
      ],
      colData2: [],
      newYear: [],
      conclusion: '',
      conclusionEn: '',
      firstChart: null,
      secondChart: null,
      thirdChart: null,
      fourChart: null
    }
  },
  watch: {
    lang() {
      this.echarts1(this.fontSize)
      this.echarts2(this.fontSize, this.iconWidth)
      this.echartLang2()
      this.echartLang3()
      if (this.lang === 'en') {
        this.colData3[0].prop = 'indexTypeEn'
      } else {
        this.colData3[0].prop = 'indexType'
      }
    }
  },
  mounted() {
    // this.getData(this.companyAaaid)
    window.addEventListener('resize', this.resizeFn)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.resizeFn)
  },
  created() {
    this.add()
  },
  methods: {
    // secondChart根据中英文显示legend文字
    echartLang2() {
      // var newOption = this.secondChart.getOption()
      // newOption.legend = { data: [this.$t('company.rating.area.items2'), this.$t('company.rating.area.items3'), this.$t('company.rating.area.items4')] }
      // newOption.series[0].name = this.$t('company.rating.area.items2')
      // newOption.series[1].name = this.$t('company.rating.area.items3')
      // newOption.series[2].name = this.$t('company.rating.area.items4')
      // this.secondChart.setOption(newOption)
      if (this.lang === 'en') {
        this.colData1[0] = { prop: 'indexTypeEn', align: 'left' }
      } else {
        this.colData1[0] = { prop: 'indexType', align: 'left' }
      }
    },
    // thirdChart根据中英文显示legend文字
    echartLang3() {
      var newOption = this.thirdChart.getOption()
      newOption.legend = { data: ['Best', 'Worst', 'Average'] }
      newOption.series[0].name = 'Best'
      newOption.series[1].name = 'Worst'
      newOption.series[2].name = 'Average'
      newOption.series[3].name = 'Subject Company'
      this.thirdChart.setOption(newOption)
      if (this.lang === 'en') {
        this.colData2 = [{ prop: 'indexTypeEn', align: 'left' }, { prop: 'percentageEn', align: 'right' }]
      } else {
        this.colData2 = [{ prop: 'indexType', align: 'left' }, { prop: 'percentage', align: 'right' }]
      }
    },
    add() {

      if (this.lang === 'en') {
        this.colData1[0] = { prop: 'indexTypeEn', align: 'left' }
        this.colData2 = [{ prop: 'indexTypeEn', align: 'left' }, { prop: 'percentageEn', align: 'right' }]
      } else {
        this.colData2 = [{ prop: 'indexType', align: 'left' }, { prop: 'percentage', align: 'right' }]
        this.colData1[0] = { prop: 'indexType', align: 'left' }
      }
      if (this.lang === 'en') {
        this.colData3[0].prop = 'indexTypeEn'
      } else {
        this.colData3[0].prop = 'indexType'
      }
      const data = {
        industryCode: this.industryCode,
        aaaId: this.companyAaaid,
        province: this.provinceEn,
        country: this.$route.query.companyCountry
      }
      const rate = this.five.replace('3A', '') ? this.five.replace('3A', '') : ''
      if (rate >= 1 && rate <= 6) {
        this.conclusion = '具有竞争力'
        this.conclusionEn = 'Competitive'
      } else if (rate >= 7 && rate <= 9) {
        this.conclusion = '遗憾竞争力'
        this.conclusionEn = 'Average'
      } else if (rate >= 10 && rate <= 12) {
        this.conclusion = '较差竞争力'
        this.conclusionEn = 'Cross Competitive'
      } else if (rate >= 13 && rate <= 19) {
        this.conclusion = '极差竞争力'
        this.conclusionEn = 'Poor'
      } else {
        this.conclusion = ''
        this.conclusionEn = ''
      }
      // const numberTable = [

      // ]
      this.provinces = []
      this.num = []
      this.List = []
      this.xAxisnum = []
      this.yAxisnum = []
      this.zAxisnum = []
      this.xAxisnums = []
      this.yAxisnums = []
      this.zAxisnums = []
      this.wAxisnums = []
      this.ratingAreaPie = []
      this.years = []
      this.newYear = []
      // 同行业企业数-数据表
      ratingAreaNumTable(data).then(res => {
        this.echart1Data = res.data.charts1
        const echart2Date = {}
        res.data.charts2.map(item => {
          if (!echart2Date[item.provinceEn]) {
            echart2Date[item.provinceEn] = {}
            this.provincesEn.push(item.province)
          }
          if (item.indexType === '最差值') {
            echart2Date[item.provinceEn].min = item.rating3a.replace('3A', '') ? item.rating3a.replace('3A', '') : ''
          } else if (item.indexType === '最好值') {
            echart2Date[item.provinceEn].max = item.rating3a.replace('3A', '') ? item.rating3a.replace('3A', '') : ''
          } else if (item.indexType === '平均值') {
            echart2Date[item.provinceEn].avg = item.rating3a.replace('3A', '') ? item.rating3a.replace('3A', '') : ''
          }
        })
        for (var key in echart2Date) {
          const d = echart2Date[key]
          this.provinces.push(key)
          this.xAxisnum.push(d.max)
          this.yAxisnum.push(d.min)
          this.zAxisnum.push(d.avg)
        }
        const echart3Date = {}
        res.data.charts3.map(item => {
          if (!echart3Date[item.year]) {
            echart3Date[item.year] = {}
          }
          if (item.indexType === '最差值') {
            echart3Date[item.year].min = item.rating3a.replace('3A', '') ? item.rating3a.replace('3A', '') : ''
          } else if (item.indexType === '最好值') {
            echart3Date[item.year].max = item.rating3a.replace('3A', '') ? item.rating3a.replace('3A', '') : ''
          } else if (item.indexType === '平均值') {
            echart3Date[item.year].avg = item.rating3a.replace('3A', '') ? item.rating3a.replace('3A', '') : ''
          } else if (item.indexType === '目标公司') {
            echart3Date[item.year].targe = item.rating3a.replace('3A', '') ? item.rating3a.replace('3A', '') : ''
          }
        })
        for (var i in echart3Date) {
          const d = echart3Date[i]
          this.years.push(i || '')
          this.xAxisnums.push(d.max)
          this.yAxisnums.push(d.min)
          this.zAxisnums.push(d.avg)
          this.wAxisnums.push(d.targe)
        }
        this.newYear = this.years.sort() ? this.years.sort() : ''
        this.echartsList = res.data.table1.map(item => {
          if (item.indexType === '目标公司') {
            item.percentage = ''
            item.percentageEn = ''
            return item
          } else {
            item.percentage = '+' + (Number(item.percentage) * 100).toFixed(2) + '%'
            item.percentageEn = item.percentage
            return item
          }
        })
        this.echartsList2 = res.data.table2.map(item => {
          if (item.indexType === '目标公司') {
            item.percentage = ''
            return item
          } else {
            item.percentage = '+' + (item.percentage * 100).toFixed(2) + '%'
            return item
          }
        })
        this.echartsList2.push('')
        this.echartsList3 = [...this.echartsList.filter(i => i.indexType !== '目标公司'),
        {
          indexType: '目标公司',
          indexTypeEn: 'Subject Company',
          percentage: this.five,
          percentageEn: this.five
        }, {
          indexType: '区域表现',
          indexTypeEn: 'Regional performance',
          percentage: this.conclusion,
          percentageEn: this.conclusionEn
        }]
        // this.echartsList4 = [...this.echartsList,
        this.echartsList4 = [...this.echartsList.filter(i => i.indexType !== '目标公司'),
        {
          indexType: '目标公司',
          indexTypeEn: 'Subject Company',
          percentage: this.five,
          percentageEn: this.five
        },
        {
          indexType: '企业所在地',
          indexTypeEn: 'location of an enterprise',
          percentage: this.province,
          percentageEn: this.province
        }
        ]
       
        res.data.ratingPies.forEach(item => {
          this.List.push({ name: item.indexType ? item.indexType : null, value: item.num ? item.num : null })
        })
        this.$nextTick(() => {
          this.resizeFn()
        })
      })
    },
    echarts1(fontSize) {
      const province = []
      const num = []
      for (var item of this.echart1Data) {
        if (this.lang === 'en') {
          province.push(item.provinceEn)
        } else {
          province.push(item.provinceEn)
        }
        num.push(item.num)
      }
      if (this.firstChart !== null) {
        this.firstChart.dispose()
      }
      this.firstChart = this.$echarts.init(this.$refs.echarts1)
      const area = this.lang === 'en' ? this.province : this.province
      const self = this
      const option = {
        color: ['#1290C9'],
        grid: {
          left: '0%',
          right: '2%',
          bottom: '5%',
          top: '10%',
          containLabel: true
        },
        tooltip: {
          formatter: function (params) {
            if (params['name'] === area) {
              return 'Subject Company: ' + params['name'] + '<br/>' + 'Number of Company : ' + params['data']
            } else {
              return 'Location: ' + params['name'] + ' <br/> ' + ' Number of Company: ' + params['data']
            }
          }
        },
        xAxis: [
          {
            type: 'category',
            data: province,
            axisLine: {
              show: true,
              lineStyle: {
                width: 1,
                color: '#142746'
              }
            },
            axisTick: {
              show: false,
              alignWithLabel: true
            },
            axisLabel: {
              interval: 0,
              formatter: function (value) {
                return value.slice(0, 2) + '\n'
              },
              // rotate: 70,
              fontSize: fontSize,
              color: '#8398BB'
            }
          }
        ],
        yAxis: {
          type: 'value',
          splitLine: {
            lineStyle: {
              width: 1,
              type: 'dashed',
              color: '#8398BB'
            }
          },
          axisLine: {
            show: true,
            lineStyle: {
              width: 1,
              color: '#142746'
            }
          },
          axisLabel: {
            fontSize: fontSize,
            color: '#8398BB'
          },
          axisTick: {
            show: false
          }
        },
        dataZoom: [
          {
            type: 'slider',
            show: true,
            zoomLock: true,
            showDetail: false,
            startValue: 0,
            endValue: 13,
            height: 6,
            bottom: '0%',
            backgroundColor: '#E9ECF0',
            fillerColor: '#1290C9',
            borderColor: '#E9ECF0',
            handleSize: '0%',
            handleIcon: this.start,
            dataBackground: {
              lineStyle: {
                color: '#E9ECF0',
                opacity: 1
              },
              areaStyle: {
                color: '#E9ECF0',
                opacity: 1
              }
            }
          }
        ],
        series: [
          {
            type: 'bar',
            barWidth: '55%',
            label: {
              show: false
            },
            itemStyle: {
              color: function (params) {
                if (params.name === area) {
                  return '#FF6A6A'
                } else {
                  return params.color
                }
              }
            },
            data: num
          }
        ]
      }
      this.firstChart.setOption(option)
      this.$nextTick(() => {
        this.firstChart.resize()
      })
    },
    echarts2(fontSize, iconWidth) {
      if (this.secondChart !== null) {
        this.secondChart.dispose()
      }
      this.secondChart = this.$echarts.init(this.$refs.echarts2)
      const v = this.lang === 'en' ? this.province : this.province
      const five = this.five.replace('3A', '') ? this.five.replace('3A', '') : ''
      const provinceLend = this.lang === 'en' ? this.province : this.provinces
      const self = this
      const option = {
        color: ['#81C97A', '#1290C9', '#FF6A6A'],
        grid: {
          left: '0%',
          right: '5%',
          bottom: '5%',
          top: '15%',
          containLabel: true
        },
        legend: {
          left: '35%',
          top: '-2%',
          textStyle: {
            lineHeight: '26'
          },
          data: ['Best', 'Worst', 'Average']
        },
        tooltip: {
          // trigger: 'item',
          showDelay: 0,
          formatter: function (params) {
            if (params.value) {
              if (params.value.length > 1) {
                return "Subject's Industry Concentration Analysis: " + params['name'] + '<br/>' + params.seriesName + ' : ' +
                  '3A' + params.value
              } else {
                return "Subject's Industry Concentration Analysis: " + params['name'] + '<br/>' + params.seriesName + ' : ' +
                  '3A' + params.value
              }
            } else {
              if (params.componentType === 'markPoint') {
                return 'Subject Company: ' + params.data.xAxis + '<br/>' + 'Rating: ' + '3A' + params.data.yAxis
              }
            }
          }
        },
        xAxis: [
          {
            type: 'category',
            boundaryGap: false,
            data: provinceLend,
            splitLine: {
              show: true,
              lineStyle: {
                width: 1,
                type: 'dashed',
                color: '#8398BB'
              }
            },
            axisLine: {
              show: true,
              lineStyle: {
                width: 1,
                color: '#142746'
              }
            },
            axisLabel: {
              interval: 0,
              formatter: function (value) {
                return value.slice(0, 2) + '\n'
              },
              fontSize: fontSize,
              color: '#8398BB'
            },
            axisTick: {
              show: false
            }
          }
        ],
        yAxis: {
          type: 'value',
          max: 19,
          min: 1,
          splitNumber: 4,
          inverse: true,
          splitLine: {
            lineStyle: {
              width: 1,
              type: 'dashed',
              color: '#8398BB'
            }
          },
          axisLine: {
            show: true,
            lineStyle: {
              width: 1,
              color: '#142746'
            }
          },
          axisLabel: {
            fontSize: fontSize,
            color: '#8398BB',
            formatter: '3A{value}'
          },
          axisTick: {
            show: false
          }
        },
        dataZoom: [
          {
            type: 'slider',
            show: true,
            zoomLock: true,
            showDetail: false,
            startValue: 0,
            endValue: 14,
            height: 6,
            bottom: '0%',
            backgroundColor: '#E9ECF0',
            fillerColor: '#1290C9',
            borderColor: '#E9ECF0',
            handleSize: '0%',
            handleIcon: this.start,
            dataBackground: {
              lineStyle: {
                color: '#E9ECF0',
                opacity: 1
              },
              areaStyle: {
                color: '#E9ECF0',
                opacity: 1
              }
            }
          }
        ],
        series: [
          {
            name: 'Best',
            type: 'line',
            smooth: true,
            data: this.xAxisnum,
            markPoint: {
              data: [
                {
                  symbolSize: '20',
                  silent: true,
                  yAxis: five,
                  xAxis: v
                }
              ],
              itemStyle: {
                color: '#ED5565'
              }
            }
          },
          {
            name: 'Worst',
            type: 'line',
            smooth: true,
            data: this.yAxisnum
          },
          {
            name: 'Average',
            type: 'line',
            smooth: true,
            data: this.zAxisnum
          }
        ]
      }
      this.secondChart.setOption(option)
      this.$nextTick(() => {
        this.secondChart.resize()
      })
    },
    echarts3(fontSize) {
      if (this.thirdChart !== null) {
        this.thirdChart.dispose()
      }
      this.thirdChart = this.$echarts.init(this.$refs.echarts3)
      const data = this.newYear
      const self = this
      const option = {
        color: ['#81C97A', '#1A2332', '#1290C9', '#FF6A6A'],
        grid: {
          left: '0%',
          right: '4%',
          bottom: '0%',
          top: '15%',
          containLabel: true
        },
        legend: {
          top: '-2%',
          // left: '0%',
          right: '10%',
          textStyle: {
            lineHeight: '26'
          },
          data: ['Best', 'Worst', 'Average']
        },
        tooltip: {
          // trigger: 'axis',
          showDelay: 0,
          formatter: function (params) {
            if (params.value.lemgth > 1) {
              return 'Year: ' + params['name'] + '<br/>' + params.seriesName + ' : ' +
                '3A' + params.value
            } else {
              return 'Year: ' + params['name'] + '<br/>' + params.seriesName + ' : ' +
                '3A' + params.value
            }
          }
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: data,
          splitLine: {
            show: true,
            lineStyle: {
              width: 1,
              type: 'dashed',
              color: '#8398BB'
            }
          },
          axisLine: {
            show: true,
            lineStyle: {
              width: 1,
              color: '#142746'
            }
          },
          axisLabel: {
            interval: 0,
            fontSize: fontSize,
            color: '#8398BB'
          },
          axisTick: {
            show: false
          }
        },
        yAxis: {
          type: 'value',
          max: 19,
          min: 1,
          splitNumber: 4,
          inverse: true,
          splitLine: {
            lineStyle: {
              width: 1,
              type: 'dashed',
              color: '#8398BB'
            }
          },
          axisLine: {
            show: true,
            lineStyle: {
              width: 1,
              color: '#142746'
            }
          },
          axisLabel: {
            fontSize: fontSize,
            color: '#8398BB',
            formatter: '3A{value}'
          },
          axisTick: {
            show: false
          }
        },
        series: [
          {
            name: 'Best',
            type: 'line',
            smooth: true,
            data: this.xAxisnums
          },
          {
            name: 'Worst',
            type: 'line',
            smooth: true,
            data: this.yAxisnums
          },
          {
            name: 'Average',
            type: 'line',
            smooth: true,
            data: this.zAxisnums
          },
          {
            name: 'Subject Company',
            type: 'line',
            smooth: true,
            data: this.wAxisnums
          }
        ]
      }
      this.thirdChart.setOption(option)
      this.$nextTick(() => {
        this.thirdChart.resize()
      })
    },
    echarts4(fontSize, pieFontSize, iconWidth) {
      if (this.fourChart !== null) {
        this.fourChart.dispose()
      }
      this.fourChart = this.$echarts.init(this.$refs.echarts4)
      const self = this
      //   const data = this.List
      const option = {
        tooltip: {
          formatter: function (params) {
            if (params.value > 1) {
              return 'Rating: ' + params['name'] + '<br/>' + 'Number of Company: ' + params['value'] + '<br/>' + '%: ' + params['percent'] + '%'
            } else {
              return 'Rating: ' + params['name'] + '<br/>' + 'Number of Company: ' + params['value'] + '<br/>' + '%: ' + params['percent'] + '%'
            }
          }
        },
        series: [
          {
            type: 'pie',
            clockwise: false,
            // minAngle: 20,
            startAngle: 270,
            center: ['50%', '50%'],
            radius: [0, '80%'],
            label: {
              show: true,
              fontSize: pieFontSize,
              formatter: function (params) {
                if (params.data.isShow) {
                  return (
                    '{per|' +
                    params.percent +
                    '%} \n {b|' +
                    params.name +
                    '} {nameStyle| }'
                  )
                } else {
                  return (
                    '{per|' + params.percent + '%} \n {b|' + params.name + '}'
                  )
                }
              },
              rich: {
                nameStyle: {
                  width: iconWidth,
                  height: iconWidth,
                  backgroundColor: {
                    image: this.start1
                  }
                },
                per: {
                  color: '#ED5565',
                  fontSize: pieFontSize
                }
              }
            },
            labelLine: {
              length: 5,
              length2: 30
            },
            itemStyle: {
              label: {
                show: true,
                formatter: '{b}:{c}\n({d}%)'
              },
              color: function (params) {
                const colorList = [
                  '#138546',
                  '#01B152',
                  '#87C547',
                  '#FFDE2A',
                  '#F79024',
                  '#EE2424'
                ]
                return colorList[params.dataIndex]
              },
              borderWidth: 0,
              labelLine: { show: true }
            },
            data: this.List
          }
        ]
      }
      this.fourChart.setOption(option)
      if (this.fourChart !== null) {
        this.fourChart.resize()
      }
    },
    resizeFn() {
      if (document.body.clientWidth > 1664) {
        this.iconWidth = 16
        this.iconWidth1 = 14
        this.fontSize = 14
        this.pieFontSize =  14
      } else {
        this.iconWidth = 10
        this.iconWidth1 = 8
        this.fontSize = 14
        this.pieFontSize = 14
      }

      this.echarts1(this.fontSize)
      this.echarts2(this.fontSize, this.iconWidth)
      this.echarts3(this.fontSize)
      this.echarts4(this.fontSize, this.pieFontSize, this.iconWidth1)
    }
  }
}
</script>
<style scoped>
 .flex_center_between_box{
    flex-wrap: wrap;
  }
.lengedBoxItems:nth-child(4) {
  margin-right: 0;
}

.mt-5 {
  margin-top: 5px;
}

/* 1920 */
@media (min-width: 1665px) {

  /* .m-t{
    margin-top:65px;
  } */
  .rateEchartItems1 {
    width: 50%;

    margin: 0 auto;

  }

  .rateEchartItems2 {
    width: 50%;

    height: 100%;
    margin: 0 auto;
  }

  .rateEchartItems3 {
    width: 60%;
    height: 100%;

    margin: 0 auto 0 auto;
  }

  .rateEchartItems4 {
    width: 60%;

    height: 100%;
    margin: 0 auto 0 auto;
    /* padding-top: 80px;
    padding-left: 92px; */
  }

  .lengedPieBox {
    top: 120px;
    right: 70px;
    width: 360px;
  }

  .lengedBarBox {
    top: 58px;
    right: 100px;
  }

  .lengedLineBox1 {
    top: 118px;
    right: 95px;
  }

  .lengedLineBox {
    top: 58px;
    right: 95px;
  }
}

/* 1280*/
@media (max-width: 1664px) {
  .flex_center_between_box{
    flex-wrap: wrap;
  }
  /* .m-t{
     margin-top: 45px;
  } */
  .rateEchartItems1 {
    width: 100%;
    height: 27.8571429rem !important;
    margin: 0 auto;
  }

  .rateEchartItems2 {
    width: 100%;
    height: 27.8571429rem !important;
    margin: 0 auto;
  }

  .rateEchartItems3 {
    width: 100%;
    height: 27.8571429rem !important;
    margin: 14px auto 0 auto;
  }

  /* .rateEchartItems4 {
    width: 386px;
    height: 238px;
    margin: 34px auto 0 auto;
  } */
  .rateEchartItems4 {
    width: 100%;
    height: 27.8571429rem !important;
    margin: 14px auto 0 auto;
    /* padding-top: 60px;
    padding-left: 62px; */
  }

  .lengedPieBox {
    top: 78px;
    right: 46px;
    width: 295px;
  }

  .lengedBarBox {
    top: 36px;
    right: 66px;
  }

  .lengedLineBox1 {
    top: 76px;
    right: 55px;
  }

  .lengedLineBox {
    top: 36px;
    right: 55px;
  }
}


</style>
