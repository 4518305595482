<template>
  <div class="rateNoSelect">
    <!-- 验证评级——行业分析 -->
    <div class="rateIndustryEchart flexBox">
      <div class="rateEchartItems">
        <div class="rateEchartTips flexCenter">
          <span class="title">Normal Distribution</span>
        </div>
        <ul class="lengedBox flexCenter lengedBarBox">
          <li class="flexCenter flex_center_start_box lengedBoxItems m-lf lengedBoxItemsText" >
            <span class="redkuai" > </span>
            <span class="">Subject Company</span>
          </li>
        </ul>
      <div class="flex_center_between_box">
        <div ref="echarts1" class="rateEchartItems1" />
        <div class="rateEchartInfo">
          <Table :title="'Probability of the subject rating in its industry:'" :table-data="echartsListRelease" :col-data="colData3" />
        </div>
      </div>
      </div>
      <div class="rateEchartItems">
        <div class="rateEchartTips flexCenter">
          <span class="title">Rating Distribution</span>
        </div>
        <div class="echartTitleBox echartTitleScatterBox" />
        <ul class="lengedBox flexCenter lengedBarBox">
          <li class="flexCenter flex_center_start_box lengedBoxItems m-lf lengedBoxItemsText" >
            <span class="redkuai" > </span>
            <span class="">Subject Company</span>
          </li>
        </ul>
        <div class="flex_center_between_box">
        <div ref="echarts2" class="rateEchartItems2" />
        <div class="rateEchartInfo">
          <Table :title="'Subject rating in its industry by financial indicators：'" :table-data="echartsListIndex" :col-data="colData1" />
        </div>
        </div>
      </div>
    </div>
    <div class="rateIndustryEchart flexBox" style="margin-top: 10px;">
      <div class="rateEchartItems">
        <div class="rateEchartTips flexCenter">
          <span class="title">Ranking of subject's rating in the industry</span>
        </div>
        <div class="flex_center_between_box">
        <div ref="echarts3" class="rateEchartItems3" />
        <div class="rateEchartInfo">
          <Table :title="'Percentile rank: % of companies that scored at or below subjects rating'" :table-data="echartsList4" :col-data="colData2" />
        </div>
        </div>
      </div>
      <div class="rateEchartItems">
        <div class="rateEchartTips flexCenter">
          <span class="title">Historical development of subject's rating in its industry</span>
        </div>
        <div class="flex_center_between_box">
        <div ref="echarts4" class="rateEchartItems4" />
        <div class="rateEchartInfo">
          <Tables :title="'Subject Rating Changes：'" :table-data="echartsList3" :col-data="colData2" />
        </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import Table from '@/components/table'
import Tables from '@/components/tables'
import {
    ratingRanking,
    ratingResearchIndustry,
    ratingNormalDesTable
  } from "@/api/companyApi";
export default {
  components: {
    Table: Table,
    Tables: Tables
  },
  props: {
    industryCodeTypeEn: {
      type: String,
      default: ''
    },
    industryCodeTypeCn: {
      type: String,
      default: ''
    },
    industryCodeTypeEnParent: {
      type: String,
      default: ''
    },
    industryCodeTypeCnParent: {
      type: String,
      default: ''
    },
    industryCode: {
      type: String,
      default: ''
    },
    companyNameEn: {
      type: String,
      default: ''
    },
    companyName: {
      type: String,
      default: ''
    },
    companyAaaid: {
      type: String,
      default: ''
    },
    five: {
      type: String,
      default: ''
    },
    financialAbstracts: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  data () {
    return {
      num: [],
      number: [
        {
          name: '红色',
          percentage: ''
        },
        {
          name: '蓝色',
          percentage: ''
        }
      ],
      years: [],
      echartsListRelease: [],
      echartsListIndex: [],
      yAxisnum: [],
      xAxisnum: [],
      zAxisnum: [],
      echartsList: [],
      echartsList2: [],
      financialIndex: [],
      yAxisTable: [],
      xAxisTable: [],
      tablePont: {},
      percentage: [],
      conclusion: '',
      conclusionEn: '',
      symbolSize: 16,
      top2: '18%',
      top4: '20%',
      value: '1',
      echartsList3: [],
      echartsList4: [],
      financeData: [],
      colData1: [
        {
          prop: 'indexType',
          align: 'left'
        },
        {
          prop: 'rating3a',
          align: 'right'
        },
        {
          prop: 'num',
          align: 'right'
        },
        {
          prop: 'percentage',
          align: 'right'
        }
      ],
      colData2: [
        {
          prop: 'name',
          align: 'left'
        },
        {
          prop: 'percentage',
          align: 'right'
        }
      ],
      colData3: [
        {
          prop: 'indexType',
          align: 'left'
        },
        {
          prop: 'rating3a',
          align: 'right'
        },
        {
          prop: 'rating',
          align: 'right'
        },
        {
          prop: 'percentage',
          align: 'right'
        }
      ],
      firstChart: null,
      secondChart: null,
      thirdChart: null,
      fourChart: null,
      echartsTableData3: []
    }
  },
  mounted () {
    this.industry()
    window.addEventListener('resize', this.resizeFn)
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.resizeFn)
  },

  watch: {
 
  },
  methods: {
    async industry () {
     this.ratingRanking()
     this.getRatingResearchIndustry()
      this.echartsTable4()
     this.financeInformation()
      this.getRatingNormalDesTable()
    },
    // firstChart根据中英文显示legend文字
    echartLang1 () {
      var newOption = this.firstChart.getOption()
      newOption.legend = { data: ['Normal Distribution'] }
      newOption.series[0].name = 'Normal Distribution'
      this.firstChart.setOption(newOption)
      if (this.lang === 'en') {
        this.colData1[0] = { prop: 'indexTypeEn', align: 'left' }
      } else {
        this.colData1[0] = { prop: 'indexType', align: 'left' }
      }
    },
    // secondChart根据中英文显示legend文字
    echartLang2 () {
      var newOption = this.secondChart.getOption()
      newOption.legend = { data: ['Revenue', 'Earning', 'Total Assets'] }
      newOption.series[0].name = 'Revenue'
      newOption.series[1].name ='Earning'
      newOption.series[2].name = 'Total Assets'
      this.secondChart.setOption(newOption)
    },
    // thirdChart根据中英文显示legend文字
    echartLang3 () {
      if (this.thirdChart) {
        var newOption = this.thirdChart.getOption()
        newOption.legend = { data: ['Industry L2', 'Industry L3'] }
        newOption.series[0].name = 'Industry L2'
        newOption.series[1].name = 'Industry L3'
        newOption.yAxis[0].data = ['By Earning', 'By Assets', 'By Revenue']
        this.thirdChart.setOption(newOption)
        this.drawEcharts3(this.fontSize)
      }
    },
    // fourChart根据中英文显示legend文字
    echartLang4 () {
      if (this.fourChart) {
        var newOption = this.fourChart.getOption()
        newOption.legend = { data: ['Industry Average', 'company.rating.history.item1'] }
        newOption.series[0].name = 'company.rating.history.item1'
        newOption.series[1].name = 'Industry Average'
        this.fourChart.setOption(newOption)
        this.drawEcharts4(this.fontSize)
      }
    },
    // 第三个echarts表格的内容
    echartsTable3 (item) {
      const numbers = [
      {
          name:'Blue',
          percentage:  this.industryCodeTypeEnParent 
        },
        {
          name: 'Red',
          percentage:this.industryCodeTypeEn 
        }]
      this.number = numbers
      this.middle = []
      this.small = []
      this.echartsList4 = []
      this.echartsList4 = [...this.number]
      if (item) {
        const average = Number((item.percentEve + item.percentAsset + item.percentProfit) * 100 / 3).toFixed(0)
        if (average <= 50) {
          this.echartsList4.push({ name:'Subject Company', percentage:  'Alert'  })
        } else if (average > 50 && average <= 70) {
          this.echartsList4.push({ name:'Subject Company', percentage: 'Weak'  })
        } else if (average > 70 && average <= 85) {
          this.echartsList4.push({ name:'Subject Company', percentage:  'Normal' })
        } else {
          this.echartsList4.push({ name:'Subject Company', percentage:  'Good' })
        }
      }
    },
    // 第四个echarts表格的内容
    echartsTable4 () {
        this.colData1[0] = { prop: 'indexTypeEn', align: 'left' }
      this.number = [
        {
          name: '红色',
          percentage:  this.industryCodeTypeEn 
        },
        {
          name: '蓝色',
          percentage: this.industryCodeTypeEnParent 
        }
      ]
      const rate = this.five.replace('3A', '') ? this.five.replace('3A', '') : ''
      if (rate >= 1 && rate <= 6) {
        this.conclusion = '表现良好'
        this.conclusionEn = 'Good'
      } else if (rate >= 7 && rate <= 9) {
        this.conclusion = '表现一般'
        this.conclusionEn = 'Normal'
      } else if (rate >= 10 && rate <= 12) {
        this.conclusion = '表现较差'
        this.conclusionEn = 'Weak'
      } else if (rate >= 13 && rate <= 19) {
        this.conclusion = '表现极差'
        this.conclusionEn = 'Alert'
      } else {
        this.conclusion = ''
        this.conclusionEn = ''
      }
      const Arrays = [
        {
          name:'Blue',
          percentage:  this.industryCodeTypeEn 
        },
        {
          name: 'Red',
          percentage: this.companyNameEn 
        },
        {
          name: 'industry.analysis',
          percentage: this.conclusionEn
        }
      ]
      this.echartsList3 = Arrays
    },
    getRatingNormalDesTable () {
        this.colData3[0].prop = 'indexTypeEn'
   
      // 数据表
      // echarts数据图
      ratingNormalDesTable({
        industryCode: this.industryCode,
        aaaId: this.companyAaaid,
        country:this.$route.query.companyCountry
      }).then(res => {
        this.echartsListRelease = res.data.industryRelease.map(item => {
          if (item.indexType === '目标公司') {
            item.percentage = ''
            return item
          } else {
            item.percentage = '+' + (item.percentage * 100).toFixed(2) + '%'
            return item
          }
        })
        this.echartsListIndex = res.data.industryIndex.map(item => {
          if (item.indexType === '目标公司') {
            item.percentage = ''
            return item
          } else {
            item.percentage = '+' + (item.percentage * 100).toFixed(2) + '%'
            return item
          }
        })
        this.echartsListIndex.push('')
        this.xAxisTable = []
        this.yAxisTable = []
        this.tablePont = {}
        const arrayList = res.data.ratingNormalDesList.filter(i => i.displayType !== '目标公司')
        arrayList.sort(function (a, b) {
          return parseInt(a.xAxis.replace('3A', '')) - parseInt(b.xAxis.replace('3A', ''))
        })
        // 匹配第一章echarts
        for (var item of arrayList) {
          this.xAxisTable.push(item.xAxis)
          this.yAxisTable.push(item.yAxis)
          if (item.xAxis === this.five) {
         
            this.tablePont = { xAxis: item.xAxis, yAxis: item.yAxis }
          }
        }
  
        // 第二张评级分布状态
        this.xAxisnum = res.data.ratingDistributionList.filter(i => i.financialIndex === '收入' && i.xAxis > 0).map(item => {
          return [item.xAxis, item.yAxis.replace('3A', '') ? item.yAxis.replace('3A', '') : '', item.num, '0']
        })
        this.yAxisnum = res.data.ratingDistributionList.filter(i => i.financialIndex === '利润' && i.xAxis > 0).map(item => {
          return [item.xAxis, item.yAxis.replace('3A', '') ? item.yAxis.replace('3A', '') : '', item.num, '0']
        })
        this.zAxisnum = res.data.ratingDistributionList.filter(i => i.financialIndex === '资产' && i.xAxis > 0).map(item => {
          return [item.xAxis, item.yAxis.replace('3A', '') ? item.yAxis.replace('3A', '') : '', item.num, '0']
        })
        this.$nextTick(() => {
          this.resizeFn()
        })
      })
    },
    getRatingResearchIndustry () {
      const years = []
      const target = []
      const mean = []
      // 公司评级历史趋势
      ratingResearchIndustry({
        industryCode: this.industryCode,
        aaaId: this.companyAaaid
      }).then(res => {
        const echart2Date = {}
        res.data.industry.map(item => {
          if (!echart2Date[item.year]) {
            echart2Date[item.year] = {}
          }
          if (item.displayType === '目标公司') {
            echart2Date[item.year].min = item.rating3a.replace('3A', '') ? item.rating3a.replace('3A', '') : ''
          } else if (item.displayType === '行业') {
            echart2Date[item.year].max = item.rating3a.replace('3A', '') ? item.rating3a.replace('3A', '') : ''
          }
        })
        for (var key in echart2Date) {
          const d = echart2Date[key]
          years.push(key || '')
          mean.push(d.max)
          target.push(d.min)
        }
        this.years = years
        this.target = target
        this.mean = mean
        this.newYear = years.sort() ? years.sort() : ''
        this.$nextTick(() => {
          this.echartLang4()
        })
      })
    },
    financeInformation () {
      // 公司在行业中的排位
      // 评级分布状态目标公司取值
      const financialAbstract = this.financialAbstracts
      if (financialAbstract) {
       
        
        this.financeData = []
        this.financeData.push([Number(financialAbstract.totaloperatereve), this.five.replace('3A', '') ? this.five.replace('3A', '') : '', '10000', '1'])
        this.financeData.push([Number(financialAbstract.jinglirun) > 0 ? Number(financialAbstract.jinglirun) : '', this.five.replace('3A', '') ? this.five.replace('3A', '') : '', '10000', '1'])
        this.financeData.push([Number(financialAbstract.sumasset) > 0 ? Number(financialAbstract.sumasset) : '', this.five.replace('3A', '') ? this.five.replace('3A', '') : '', '10000', '1'])
      
      } 
    },
    ratingRanking () {
      const paramsId = {
        aaaId: this.companyAaaid
      }
      const middle = this.middle || []
      const small = this.small || []
      ratingRanking(paramsId).then(res => {
        if (res.code === '1' && res.msg) {
          this.$message.warning(res.msg)
        } else {
          this.echartsTableData3 = res.data[1]
          this.echartsTable3(res.data[1])
          res.data.map(item => {
            if (item.displayType === '中类排名') {
              middle.push((item.percentProfit) * 100, (item.percentAsset) * 100, (item.percentEve) * 100)
            } else if (item.displayType === '小类排名') {
              small.push((item.percentProfit) * 100, (item.percentAsset) * 100, (item.percentEve) * 100)
            }
          })
          this.middle = middle
          this.small = small
          this.$nextTick(() => {
            this.echartLang3()
          })
        }
      })
    },
    drawEcharts1 () {
      if (this.firstChart !== null) {
        this.firstChart.dispose()
      }
      this.firstChart = this.$echarts.init(this.$refs.echarts1)
      const data =  this.companyNameEn 
      const self = this
      const option = {
        color: ['#80ABF9'],
        grid: {
          left: '10%',
          right: '8%',
          bottom: '10%',
          top: '10%'
        },
        legend: {
          right: '10%',
          data: ['Normal Distribution']
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: this.xAxisTable,
          axisLabel: {
            color: '#8398BB'
          },
          axisTick: {
            show: false
          }
        },
        yAxis: {
          type: 'value',
          show: true,
          axisLabel: {
            formatter: function () {
              return ''
            }
          },
          splitLine: {
            show: false
          },
          axisTick: {
            show: false
          }
        },
        series: [{
          name: 'Normal Distribution',
          data: this.yAxisTable,
          type: 'line',
          areaStyle: {
            color: '#80ABF9',
            opacity: 0.3,
            origin: 'start'
          },
          smooth: true,
          markPoint: {
            symbol: 'circle',
            symbolSize: 12,
            itemStyle: {
              color: '#FF6A6A'
            },
            data: [
              this.tablePont
            ]
          }
        }
        ],
        tooltip: {
          formatter: function (params) {
            if (params.color === '#FF6A6A') {
              return   'Subject Company: ' + data + '<br/>' + 'Rating: ' + params.data.xAxis + '<br/>' + 'Number of Company:' + params.data.yAxis
            } else {
              return 'Rating: ' + params['name'] + '<br/>' +  'Number of Company: ' + params['data']
            }
          }
        }
      }
      this.firstChart.setOption(option)
      this.firstChart.resize()
    },
    // 第二张echarts图
    drawEcharts2 (fontSize) {
      if (this.secondChart !== null) {
        this.secondChart.dispose()
      }
      this.secondChart = this.$echarts.init(this.$refs.echarts2)
      this.xAxisnum.push(this.financeData[0])
      this.yAxisnum.push(this.financeData[1])
      this.zAxisnum.push(this.financeData[2])
      const data = [this.xAxisnum, this.yAxisnum, this.zAxisnum]
   
      var self = this
      // const Five = this.five.replace('3A', '') ? this.five.replace('3A', '') : ''
      const copyCompany = this.lang === 'en' ? this.companyNameEn : this.companyName
      const option = {
        grid: {
          left: '2%',
          right: '3%',
          bottom: '0%',
          top: '15%',
          containLabel: true
        },
        color: ['#1290C9', '#81C97A', '#FF7600'],
        tooltip: {
          // trigger: 'axis',
          showDelay: 0,
          formatter: function (params) {
            if (params.color === '#FF6A6A') {
              if (params.value[0] < 1000000) {
                return  'Subject Company: ' + copyCompany + '<br/>' + params.seriesName + '：' +
                  (params.value[0] / 1000).toFixed(2) + 'k<br/>' +
                  'Rating: ' +
                  '3A' + params.value[1]
              } else if (params.value[0] >= 1000000 && params.value[0] < 1000000000) {
                return  'Subject Company: ' + copyCompany + '<br/>' + params.seriesName + '：' +
                  (params.value[0] / 1000000).toFixed(2) + 'M<br/>' +
                'Rating: ' +
                  '3A' + params.value[1]
              } else if (params.value[0] >= 1000000000 && params.value[0] < 1000000000000) {
                return  'Subject Company: ' + copyCompany + '<br/>' + params.seriesName + '：' +
                  (params.value[0] / 1000000000).toFixed(2) + 'B<br/>' +
              'Rating: ' +
                  '3A' + params.value[1]
              } else {
                return 'Subject Company: ' + copyCompany + '<br/>' + params.seriesName + '：' + params.value[0] + '~' +
                  (params.value[0] / 1000000000000).toFixed(2) + 'B<br/>' +
          'Rating: ' +
                  '3A' + params.value[1]
              }
            } else {
              if (params.value[0] < 1000000) {
                return params.seriesName + '：' +
                  (params.value[0] / 1000).toFixed(2) + 'k<br/>' +
               'Rating: ' +
                  '3A' + params.value[1] + '<br/>' +
                 'Number of Company' + ': ' + params.value[2]
              } else if (params.value[0] >= 1000000 && params.value[0] < 1000000000) {
                return params.seriesName + '：' +
                  (params.value[0] / 1000000).toFixed(2) + 'M<br/>' +
                  'Rating: ' +
                  '3A' + params.value[1] + '<br/>' +
                 'Number of Company' + ': ' + params.value[2]
              } else if (params.value[0] >= 1000000000 && params.value[0] < 1000000000000) {
                return params.seriesName + '：' +
                  (params.value[0] / 1000000000).toFixed(2) + 'B<br/>' +
             'Rating: ' +
                  '3A' + params.value[1] + '<br/>' +
                 'Number of Company' + ': ' + params.value[2]
              } else {
                return params.seriesName + '：' + params.value[0] + '~' +
                  (params.value[0] / 1000000000000).toFixed(2) + 'B<br/>' +
                'Rating: ' +
                  '3A' + params.value[1] + '<br/>' +
               'Number of Company' + ': ' + params.value[2]
              }
            }
          }
        },
        xAxis: {
          show: true,
          type: 'log',
          scale: true,
          axisLabel: {
            show: true,
            // formatter: '{value}',
            formatter: (value) => {
              if (value < 1000000) {
                return value / 1000 + 'k'
              } else if (value < 1000000000) {
                return value / 1000000 + 'm'
              } else if (value > 1000000000000) {
                return value / 1000000000 + 'b'
              } else {
                return value / 1000000000 + 'b'
              }
            },
            color: '#8398BB'
          },
          splitLine: {
            lineStyle: {
              width: 1,
              type: 'dashed',
              color: '#8398BB'
            }
          },
          axisLine: {
            show: true,
            lineStyle: {
              width: 1,
              color: '#142746'
            }
          },
          axisTick: {
            show: false
          }
        },
        yAxis: {
          type: 'value',
          max: 19,
          min: 1,
          splitNumber: 4,
          inverse: true,
          splitLine: {
            lineStyle: {
              width: 1,
              type: 'dashed',
              color: '#8398BB'
            }
          },
          axisLine: {
            show: true,
            lineStyle: {
              width: 1,
              color: '#142746'
            }
          },
          axisLabel: {
            fontSize: fontSize,
            color: '#8398BB',
            formatter: '3A{value}'
          },
          axisTick: {
            show: false
          }
        },
        legend: {
          left: '-1%',
          top: '7%',
          data: ['Revenue', 'Earning', 'Total Assets'],
          textStyle: {
            fontSize: 14
          }
        },
        series: [
          {
            name: 'Revenue',
            data: data[0],
            type: 'scatter',
            itemStyle: {
              color: function (params) {
                if (params.data && params.data[3] === '1') {
                  return '#FF6A6A'
                } else {
                  return params.color
                }
              }
            },
            symbolSize: function (data) {
              if (data && data[2]) {
                return Math.log(data[2]) + 8
              }
              return 8
            }
          },
          {
            name: 'Earning',
            data: data[1],
            type: 'scatter',
            itemStyle: {
              color: function (params) {
                if (params.data && params.data[3] === '1') {
                  return '#FF6A6A'
                } else {
                  return params.color
                }
              }
            },
            symbolSize: function (data) {
              if (data && data[2]) {
                return Math.log(data[2]) + 8
              }
              return 8
            }
          },
          {
            name: 'Total Assets',
            data: data[2],
            type: 'scatter',
            itemStyle: {
              shadowBlur: 6,
              shadowColor: 'rgba(68, 99, 1567, 0.45)',
              shadowOffsetY: 4,
              opacity: 1,
              color: function (params) {
                if (params.data && params.data[3] === '1') {
                  return '#FF6A6A'
                } else {
                  return params.color
                }
              }
            },
            symbolSize: function (data) {
              if (data && data[2]) {
                return Math.log(data[2]) + 8
              }
              return 8
            }
          }
        ]
      }
      this.secondChart.setOption(option)
      this.secondChart.resize()
    },
    drawEcharts3 (fontSize) {
      // if (this.middle && this.middle.length <= 0 || this.small && this.small.length <= 0) {
      //   this.ratingRanking()
      //   return
      // }
      if (this.thirdChart !== null) {
        this.thirdChart.dispose()
      }
      this.thirdChart = this.$echarts.init(this.$refs.echarts3)
      var self = this
      const option = {
        color: ['#FF6A6A', '#1290C9'],
        barWidth: '30%',
        grid: {
          left: '3%',
          right: '5%',
          bottom: '-0.5%',
          top: '17%',
          containLabel: true
        },
        legend: {
          left: '-1%',
          top: '3%',
          data: ['Industry L2','Industry L3']
        },
        tooltip: {
          trigger: 'item',
          //   formatter: '{c}%',
          formatter: function (params) {
            if (params['componentIndex'] === 0) {
              return ('Industry Category' + ': ' + params['seriesName'] + '<br/>' + params['name'] + ' : ' + params['value'].toFixed(0) + '%')
            } else if (params['componentIndex'] === 1) {
              return ('Industry Category' + ': ' + params['seriesName'] + '<br/>' + params['name'] + ' : ' + params['value'].toFixed(0) + '%')
            }
          }
        },
        xAxis: {
          type: 'value',
          minInterval: 25,
          maxInterval: 25,
          splitLine: {
            lineStyle: {
              width: 1,
              type: 'dashed',
              color: '#8398BB'
            }
          },
          axisLine: {
            show: true,
            lineStyle: {
              width: 1,
              color: '#142746'
            }
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            fontSize: fontSize,
            color: '#8398BB',
            formatter: '{value}%'
          },
          splitNumber: 4
        },
        yAxis: {
          type: 'category',
          data: ['By Earning', 'By Assets','By Revenue'],
          axisLine: {
            show: true,
            lineStyle: {
              width: 1,
              color: '#142746'
            }
          },
          axisLabel: {
            color: '#8398BB'
          },
          axisTick: {
            show: false
          }
        },
        series: [
          {
            name: 'Industry L2',
            type: 'bar',
            barGap: '0%',
            label: {
              show: true,
              color: '#fff',
              fontSize: fontSize,
              position: 'insideRight',
              //   formatter: '{c}%',
              formatter: function (params) {
                return (params['value'].toFixed(0) + '%')
              }
            },
            data: this.middle || []
          },
          {
            name: 'Industry L3',
            type: 'bar',
            label: {
              show: true,
              color: '#fff',
              fontSize: fontSize,
              position: 'insideRight',
              //   formatter: '{c}%',
              formatter: function (params) {
                return (params['value'].toFixed(0) + '%')
              }
            },
            data: this.small || []
          }
        ]
      }
      this.thirdChart.setOption(option)
      this.thirdChart.resize()
    },
    drawEcharts4 (fontSize) {
      // if (this.target && this.target.length <= 0 || this.mean && this.mean.length <= 0) {
      //   this.getRatingResearchIndustry()
      //   return
      // }
      if (this.fourChart !== null) {
        this.fourChart.dispose()
      }
      this.fourChart = this.$echarts.init(this.$refs.echarts4)
      const data = this.newYear
      var self = this
      const option = {
        color: [ '#FF6A6A','#1290C9'],
        grid: {
          left: '0%',
          right: '5%',
          bottom: '0%',
          top: '25%',
          containLabel: true
        },
        legend: {
          top: '5%',
          left: '-1%',
          data: ['Industry Average', 'Subject Company']
        },
        tooltip: {
          // trigger: 'axis',
          showDelay: 0,
          formatter: function (params) {
            if (params.value.lemgth > 1) {
              return 'Year: ' + params['name'] + '<br/>' + params.seriesName + ' : ' +
                '3A' + params.value
            } else {
              return 'Year: ' + params['name'] + '<br/>' + params.seriesName + ' : ' +
                '3A' + params.value
            }
          }
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: data,
          splitLine: {
            show: true,
            lineStyle: {
              width: 1,
              type: 'dashed',
              color: '#8398BB'
            }
          },
          axisLine: {
            show: true,
            lineStyle: {
              width: 1,
              color: '#142746'
            }
          },
          axisLabel: {
            interval: 0,
            fontSize: fontSize,
            color: '#8398BB'
          },
          axisTick: {
            show: false
          }
        },
        yAxis: {
          type: 'value',
          max: 19,
          min: 1,
          splitNumber: 4,
          inverse: true,
          splitLine: {
            lineStyle: {
              width: 1,
              type: 'dashed',
              color: '#8398BB'
            }
          },
          axisLine: {
            show: true,
            lineStyle: {
              width: 1,
              color: '#142746'
            }
          },
          axisLabel: {
            fontSize: fontSize,
            color: '#8398BB',
            formatter: '3A{value}'
          },
          axisTick: {
            show: false
          }
        },
        series: [
          {
            name: 'Subject Company',
            type: 'line',
            smooth: true,
            data: this.target || []
          },
          {
            name: 'Industry Average',
            type: 'line',
            smooth: true,
            data: this.mean || []
          }
        ]
      }
      this.fourChart.setOption(option)
      this.fourChart.resize()
    },
    resizeFn () {
      if (document.body.clientWidth > 1664) {
        this.top2 = '18%'
        this.top4 = '20%'
        this.fontSize = 14
        this.pieFontSize = 14
      } else {
        this.top2 = '20%'
        this.top4 = '25%'
        this.fontSize = 14
        this.pieFontSize = 14
      }
      this.drawEcharts1()
      this.drawEcharts2(this.fontSize)
      this.drawEcharts3(this.fontSize)
      this.drawEcharts4(this.fontSize)
    }
  }
}
</script>
<style scoped>
/* 1920 */
@media (min-width: 1665px) {
  /* .m-lf {
    margin-left: 160px;
  } */
  .rateEchartItems1 {
    width: 54%;
    height: 27.8571429rem;
    margin: 0 auto;
  }
  .rateEchartItems2 {
    width: 54%;
    height: 27.8571429rem;
    margin: 0 auto;
  }
  .rateEchartItems3 {
    width: 54%;
    height: 17.8571429rem;
    margin: 0 auto 0 auto;
  }
  .rateEchartItems3 + .rateEchartInfo .rateEchartInfoTitle {
    letter-spacing: -0.3px;
  }
  .rateEchartItems4 {
    width: 54%;
    height: 17.8571429rem;
    margin: 0 auto;
  }

  .echartTitleLineBox {
    right: 106px;
    top: 134px;
  }
  .lengedLineBox {
    right: 106px;
    top: 115px;
  }
  .echartTitleScatterBox {
    right: 106px;
    top: 80px;
  }
  .lengedScatterBox {
    right: 106px;
    top: 58px;
  }
  .rateIndustrySelect ::v-deep .el-input__inner {
    height: 38px;
    padding-left: 20px;
    border-radius: 20px;
  }
  .rateIndustrySelect ::v-deep .el-input__suffix {
    right: 15px;
  }
  .rateIndustrySelect ::v-deep .el-input.is-focus .el-input__inner {
    border-radius: 20px;
  }
}
/* 1280*/
@media (max-width: 1664px) {
  .flex_center_between_box{
    flex-wrap: wrap;
  }
  /* .m-lf {
    margin-left: 80px;
  } */
  .rateEchartItemsBox1 {
    width: 322px;
    margin: 20px auto 0 auto;
  }
  .rateEchartItems1 {
    width: 100%;
    height: 27.8571429rem;
    margin: 0 auto;
  }
  .rateEchartItems2 {
    width: 100%;
    height: 27.8571429rem;
    margin: 0 auto;
  }
  .rateEchartItems3 {
    width: 100%;
    height: 17.8571429rem;
    margin: 0px auto 0 auto;
  }
  .rateEchartItems3 + .rateEchartInfo .rateEchartInfoTitle {
    letter-spacing: -0.3px;
  }
  .rateEchartItems4 {
    width: 100%;
    height: 17.8571429rem;
    margin: 0 auto;
  }

  .echartTitleLineBox {
    right: 75px;
    top: 90px;
  }
  .echartTitleBox > div {
    font-size: 12px;
    transform: scale(0.8);
    transform-origin: right center;
    white-space: normal;
  }
  .lengedLineBox {
    right: 68px;
    top: 75px;
  }
  .echartTitleScatterBox {
    right: 75px;
    top: 58px;
  }
  .lengedScatterBox {
    right: 70px;
    top: 40px;
  }
  .rateIndustrySelect ::v-deep .el-input__inner {
    height: 26px;
    padding-left: 14px;
    border-radius: 14px;
  }
  .rateIndustrySelect ::v-deep .el-input__suffix {
    right: 10px;
  }
  .rateIndustrySelect ::v-deep .el-select__caret {
    line-height: 26px;
  }
  .rateIndustrySelect ::v-deep .el-input.is-focus .el-input__inner {
    border-radius: 14px;
  }
}
</style>
