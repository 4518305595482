<template>
  <div class="rateNoSelect">
    <!-- 验证评级——规模分析 -->
    <div class="rateIndustryEchart flexBox">
      <div class="rateEchartItems">
        <div class="rateEchartTips flexCenter">
          <span class="title">Rating analysis of companies of different sizes in the same industry</span>
        </div>
        <ul class="lengedBox flexCenter lengedBarBox">
          <li class="flexCenter flex_center_start_box lengedBoxItems m-lf lengedBoxItemsText" >
            <span class="redkuai" > </span>
            <span class="">Subject Company</span>
          </li>
        </ul>
        <div class="flex_center_between_box">
        <div
          ref="echarts1"
          class="rateEchartItems1"
        />
        <div class="rateEchartInfo">
          <Table
            :title="'Probability of subject rating in its industry within its size'"
            :table-data="echartsList1"
            :col-data="colData4"
          />
        </div>
        </div>
      </div>
      <div class="rateEchartItems">
        <div class="rateEchartTips flexCenter">
          <span class="title">Historical development of subject's rating in its company size group</span>
        </div>
        <ul class="lengedBox flexCenter lengedBarBox">
          <li class="flexCenter flex_center_start_box lengedBoxItems m-lf lengedBoxItemsText" >
            <span class="redkuai" > </span>
            <span class="">Subject Company</span>
          </li>
        </ul>
        <div class="flex_center_between_box">
        <div
          ref="echarts2"
          class="rateEchartItems2"
        />
        <div class="rateEchartInfo">
          <Table
            :title="'Subject rating comparing with its peers in its industry:'"
            :table-data="echartsList2"
            :col-data="colData1"
          />
        </div>
        </div>
      </div>
    </div>
    <div class="rateIndustryEchart flexBox" style="margin-top: 10px;">
      <div class="rateEchartItems">
        <div class="rateEchartTips flexCenter">
          <span class="title">Rating Ranking of the companies in the same size in the same industry</span>
        </div>
        <div class="flex_center_between_box" style="margin-top: 1.42857143rem;">
        <div
          ref="echarts3"
          class="rateEchartItems3"
        >
          <el-table
            :data="tableData"
            class="title rate_scale_table"
            stripe
            :header-cell-style="{textAlign: 'center'}"
            :cell-class-name="tableCellClass"
            style="width: 100%"
          >
            <el-table-column
              prop="date"
              :label="'Rank'"
              align="center"
            />
            <el-table-column
              prop="name"
              show-overflow-tooltip
              :label="'Company Name'"
              align="left"
              min-width="170"
            >
              <template slot-scope="scope">
                <span class="jumpName active" style="cursor: pointer" @click="jumpClick(scope.row)">{{ lang === 'cn' ? scope.row.name :scope.row.nameEn }}</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="province"
       
              :label="'Company Size'"
              align="center"
            >
              <template slot-scope="scope">
                <span>{{ lang === 'cn' ? scope.row.province : scope.row.provinceEn }}</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="city"
          
              :label="'Rating'"
              align="center"
            />
          </el-table>
        </div>
        <div class="rateEchartInfo">
          <Table
            :title="'Probability of subjects rating in its industry within its size'"
            :table-data="echartsList4"
            :col-data="colData3"
          />
        </div>
        </div>
      </div>
      <div class="rateEchartItems">
        <div class="rateEchartTips flexCenter">
          <span class="title">Rating statistics of companies in the same size and same industry</span>
        </div>
        <div class="flex_center_between_box" style="margin-top: 1.42857143rem;">
        <div
          ref="echarts4"
          class="rateEchartItems4"
        />
        <div class="rateEchartInfo" style="width: 35%;">
          <Table
            :title="'Probability of subjects rating in its industry within its size'"
            :table-data="echartsList5"
            :col-data="colData3"
          />
        </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Table from '@/components/table'
import { ratingSize }  from "@/api/companyApi";
import { mapGetters } from 'vuex'
export default {
  components: {
    Table: Table
  },
  props: {
    industryCode: {
      type: String,
      default: ''
    },
    companyAaaid: {
      type: String,
      default: ''
    },
    enterprise: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  data () {
    return {
      iconWidth: 16,
      iconWidth1: 14,
      fontSize: 12,
      lang:'en',
      pieFontSize: 14,
      conclusion: '',
      conclusionEn: '',
      five: '',
      start: 'image://' + require('@/assets/images/system/credit/start.png'),
      start1: require('@/assets/images/system/credit/start.png'),
      value: '1',
      year: [],
      echartsList1: [],
      echartsList2: [],
      echartsList4: [
        {
          indicators: '观察的规模',
          value: ''
        }
      ],
      echartsList5: [],
      colData1: [
        {
          prop: 'indicators',
          align: 'left'
        },
        {
          prop: 'rating3a',
          align: 'right'
        },
        {
          prop: 'num',
          align: 'right'
        },
        {
          prop: 'value',
          align: 'right'
        }
      ],
      colData3: [
        {
          prop: 'indicators',
          align: 'left'
        },
        {
          prop: 'value',
          align: 'right'
        }
      ],
      colData4: [
        {
          prop: 'indicators',
          align: 'left'
        },
        {
          prop: 'rating3a',
          align: 'right'
        },
        {
          prop: 'rating',
          align: 'right'
        },
        {
          prop: 'value',
          align: 'right'
        }
      ],
      tableData: [],
      kinds: [],
      newYear: [],
      echart1Date: [],
      firstChart: null,
      secondChart: null,
      thirdChart: null,
      fourChart: null
    }
  },
  computed: {
    // ...mapGetters({
    //   lang: 'lang'
    // })
  },
  watch: {
    lang () {
      this.echarts1()
      this.echartLang()
      if (this.lang === 'en') {
        this.colData4[0].prop = 'indicatorsEn'
      } else {
        this.colData4[0].prop = 'indicators'
      }
    }
  },
  mounted () {
    window.addEventListener('resize', this.resizeFn)
  },
  destroyed () {
    window.removeEventListener('resize', this.resizeFn)
  },
  created () {
    this.size()
  },
  methods: {
    tableCellClass ({ row, column, rowIndex, columnIndex }) {
      if (row.name === this.enterprise.companyName) {
        return 'cellBg'
      }
    },
    size () {
      // this.echartsList5 = Arrays
      const paramsThree = {
        industryCode: this.industryCode,
        aaaId: this.companyAaaid,
        companyName: this.enterprise.companyName,
        country:this.$route.query.companyCountry
      }
      this.companySize = []
      this.num = []
      this.List = []
      this.year = []
      this.newYear = []
      this.xAxisnum = []
      this.yAxisnum = []
      this.zAxisnum = []
      this.wAxisnums = []
      this.kinds = []
      this.echarts1Data = []
      ratingSize(paramsThree).then(res => {
        for (var item of res.data.charts1) {
          // this.echarts1Data
          // this.companySize.push(item.companySize)
          // this.num.push(item.num)
          if(item.companySize=='微型'){
            item.companySizeEn='Micro'
          }
          if(item.companySize=='小型'){
            item.companySizeEn='SME'
          }
          if (item.rating3a) {
            this.echarts1Data.push([item.companySize, item.rating3a.replace('3A', '')])
            this.echarts1Data.push([item.companySizeEn, item.rating3a.replace('3A', '')])
          }
        }
        const echart2Date = {}
        res.data.charts2.map(item => {
          if (!echart2Date[item.year]) {
            echart2Date[item.year] = {}
          }
          if (item.rating3a) {
            if (item.indexType === '最差值') {
              echart2Date[item.year].min = item.rating3a.replace('3A', '') ? item.rating3a.replace('3A', '') : ''
            } else if (item.indexType === '最好值') {
              echart2Date[item.year].max = item.rating3a.replace('3A', '') ? item.rating3a.replace('3A', '') : ''
            } else if (item.indexType === '平均值') {
              echart2Date[item.year].avg = item.rating3a.replace('3A', '') ? item.rating3a.replace('3A', '') : ''
            }
            else if (item.indexType === '目标公司') {
            // echart3Date[item.year].targe = item?.rating_grade_3a?.replace('3A', '') ? item?.rating_grade_3a?.replace('3A', '') : ''
            echart2Date[item.year].targe = item?.rating3a?.replace('3A', '') ? item?.rating3a?.replace('3A', '') : ''
            // 新字段

          }
          }
        })
        for (var key in echart2Date) {
          const d = echart2Date[key]
          this.year.push(key || '')
          this.xAxisnum.push(d.max)
          this.yAxisnum.push(d.min)
          this.zAxisnum.push(d.avg)
          this.wAxisnums.push(d.targe)
       
        }
        // this.newYear = this.year.sort((a, b) => Number(b) - Number(a))
        this.newYear = this.year.sort()
        this.tableData = res.data.table3.map(item => {
          item.date = item.rank
          item.name = item.companyName
          item.nameEn = item.companyNameEn
          item.province = item.size
          item.provinceEn = item.sizeEn
          item.city = item.rating
          return item
        })
        this.echartsList1 = res.data.table1.map(item => {
        
          if (item.indicators === '目标公司') {
            item.value = ''
            return item
          } else {
            item.value = '+' + (item.value * 100).toFixed(2) + '%'
            // item.value = '+' + (item.value * 100).toFixed(2)
            return item
          }
        })
        this.echartsList2 = res.data.table2.map(item => {
          if (item.indicators === '目标公司') {
            item.value = ''
            return item
          } else {
            item.value = '+' + (item.value * 100).toFixed(2) + '%'
            // item.value = '+' + (item.value * 100).toFixed(2)
            return item
          }
        })
        const numbers = {
          indicators: '观察的规模',
          indicatorsEn: 'Scale of observation',
          value: ''
        }
        const targetMaxYear = res.data.targetMaxYear
        if (targetMaxYear) {
          numbers.value = this.lang === 'en' ? targetMaxYear.comSizeEn : targetMaxYear.comSize
        }
        this.echartsList2.push(numbers)
        this.kinds = targetMaxYear
        // if (this.kinds && this.kinds['rating_grade_3a']) {
          if (this.kinds && this.kinds['ratingGrade']) {
          this.five = this.kinds['ratingGrade'].replace('3A', '') ? this.kinds['ratingGrade'].replace('3A', '') : ''
          const rate = this.five.replace('3A', '') ? this.five.replace('3A', '') : ''
          if (rate >= 1 && rate <= 6) {
            this.conclusion = '表现良好'
            this.conclusionEn = 'Good'
          } else if (rate >= 7 && rate <= 9) {
            this.conclusion = '表现一般'
            this.conclusionEn = 'Normal'
          } else if (rate >= 10 && rate <= 12) {
            this.conclusion = '表现较差'
            this.conclusionEn = 'Weak'
          } else if (rate >= 13 && rate <= 19) {
            this.conclusion = '表现极差'
            this.conclusionEn = 'Alert'
          } else {
            this.conclusion = ''
            this.conclusionEn = ''
          }
          res.data.ratingPies.forEach(item => {
            this.List.push({ name: item.indexType, value: item.num })
          })
          if (this.lang === 'en') {
            this.colData1[0] = { prop: 'indicatorsEn', align: 'left' }
            this.colData3[0] = { prop: 'indicatorsEn', align: 'left' }
          } else {
            this.colData1[0] = { prop: 'indicators', align: 'left' }
            this.colData3[0] = { prop: 'indicators', align: 'left' }
          }
          if (this.lang === 'en') {
            this.colData4[0].prop = 'indicatorsEn'
          } else {
            this.colData4[0].prop = 'indicators'
          }
        }
        this.$nextTick(() => {
          this.resizeFn()
          this.echartLang()
        })
      })
    },
    jumpClick (row) {
      // const routeQuery = this.$router.resolve({
      //   path: '/system/credit',
      //   query: {
      //     aaaId: row.aaaId,
      //     companyName: row.name,
      //     companyNameEn: row.nameEn
      //   }
      // })
      // window.open(routeQuery.href, '_blank')
      // this.$router.push({
      //   path: '/system/industryResearch',
      //   query: {
      //     aaaId: row.aaaId,
      //     companyName: row.name,
      //     companyNameEn: row.nameEn
      //   }
      // })
    },
    echarts1 (fontSize) {
      if (this.firstChart !== null) {
        this.firstChart.dispose()
      }
      this.firstChart = this.$echarts.init(this.$refs.echarts1)
      // const title = this.kinds.comsize
      const option = {
        color: ['#7C95C5'],
        grid: {
          left: '0%',
          right: '3%',
          bottom: '0%',
          top: '12%',
          containLabel: true
        },
        tooltip: {
          show: true,
          formatter: (params) => {
            if (params.color === '#DD5145') {
              return'Subject Company<br/>' +  'Company Size: ' + params.value[0] + '<br/>' +  'Rating: 3A' + params.value[1]
            } else {
              return 'Company Size: ' + params.value[0] + '<br/>'  + 'Rating: 3A' + params.value[1]
            }
          }
        },
        xAxis: [
          {
            type: 'category',
            data: this.lang === 'en' ? ['Micro', 'SME', 'Medium', 'Large'] : ['微型', '小型', '中型', '大型'],
            axisLine: {
              show: true,
              lineStyle: {
                width: 1,
                color: '#142746'
              }
            },
            axisTick: {
              show: false,
              alignWithLabel: true
            },
            axisLabel: {
              fontSize: fontSize,
              color: '#8398BB'
            }
          }
        ],
        yAxis: {
          type: 'value',
          max: 19,
          min: 1,
          splitNumber: 4,
          inverse: true,
          splitLine: {
            lineStyle: {
              width: 1,
              type: 'dashed',
              color: '#8398BB'
            }
          },
          axisLine: {
            show: true,
            lineStyle: {
              width: 1,
              color: '#142746'
            }
          },
          axisLabel: {
            fontSize: fontSize,
            color: '#8398BB',
            formatter: '3A{value}'
          },
          axisTick: {
            show: false
          }
        },
        series: [
          {
            // name: '收入',
            data: this.echarts1Data,
            type: 'scatter',
            itemStyle: {
              color: '#7C95C5'
              // function (params) {
              //   if (params.name === '小型') {
              //     return '#DD5145'
              //   } else {
              //     return params.color
              //   }
              // }
            },
            symbolSize: 10
          },
          {
            data: [[this.lang === 'en' ? this.kinds['comSizeEn'] : this.kinds['comSize'], this.kinds && this.kinds['ratingGrade'] ? this.kinds['ratingGrade'].replace('3A', '') : '']],
            type: 'scatter',
            itemStyle: {
              color: '#DD5145'
            },
            symbolSize: 10
          },
          // {
          //   type: 'bar',
          //   barWidth: '48%',
          //   label: {
          //     show: false
          //   },
          //   itemStyle: {
          //     color: function (params) {
          //       if (params.name === '小型') {
          //         return '#E85367'
          //       } else {
          //         return params.color
          //       }
          //     }
          //   },
          //   data: this.num
          // }
        ]
      }
      this.firstChart.setOption(option)
      this.firstChart.resize()
    },
    // echarts根据中英文显示legend文字
    echartLang () {
      var newOption = this.secondChart.getOption()
      newOption.legend = { data: ['Best', 'Worst', 'Average'] }
      newOption.series[0].name = 'Best'
      newOption.series[1].name = 'Worst'
      newOption.series[2].name = 'Average'
      this.secondChart.setOption(newOption)
      if (this.lang === 'en') {
        this.colData1[0] = { prop: 'indicatorsEn', align: 'left' }
        this.colData3[0] = { prop: 'indicatorsEn', align: 'left' }
      } else {
        this.colData1[0] = { prop: 'indicators', align: 'left' }
        this.colData3[0] = { prop: 'indicators', align: 'left' }
      }
      const numbers =
        {
          indicators: '观察的规模',
          indicatorsEn: 'Scale of observation',
          value: ''
        }
      const targetMaxYear = this.kinds
      if (targetMaxYear) {
        numbers.value = this.lang === 'en' ? targetMaxYear.comSizeEn : targetMaxYear.comSize
      }
      this.echartsList2[this.echartsList2.length - 1] = numbers
      this.echartsList4 = [...this.echartsList1.filter(i => i.indicators !== '目标公司'),
        numbers
      ]
      this.echartsList4.push('')
// 表格数据 第一个table过滤的值
      const Arrays = [
        {
          indicators: '观察的规模',
          indicatorsEn: 'Scale of observation',
          value: ''
        },
        {
          indicators: '规模表现',
          indicatorsEn: 'Performance',
          value: this.lang === 'en' ? this.conclusionEn : this.conclusion
        }
      ]
      if (targetMaxYear) {
        Arrays[0].value = this.lang === 'en' ? targetMaxYear.comSizeEn : targetMaxYear.comSize
      }
      this.echartsList5 = [...this.echartsList1.filter(i => i.indicators !== '目标公司'),
        ...Arrays
      ]
    },
    echarts2 (fontSize, iconWidth) {
      if (this.secondChart !== null) {
        this.secondChart.dispose()
      }
      this.secondChart = this.$echarts.init(this.$refs.echarts2)
  
      const data = this.newYear?.slice(-5)

      this.yAxisnum=this.yAxisnum?.slice(-5)
      this.zAxisnum=this.zAxisnum?.slice(-5)
      this.wAxisnums=this.wAxisnums?.slice(-5)
      this.xAxisnum=this.xAxisnum?.slice(-5)
 
   

      const v = this.kinds.year
      const five = this.five
      const option = {
        color: ['#81C97A', '#1A2332', '#1290C9', '#FF6A6A'],
        grid: {
          left: '0%',
          right: '5%',
          bottom: '0%',
          top: '12%',
          containLabel: true
        },
        legend: {
          left: '35%',
          top: '-2%',
          data: ['Max', 'Min', 'Average'],
          textStyle: {
            lineHeight: '26'
          }
        },
        tooltip: {
          // trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          formatter: (params) => {
            if (params.value) {
              if (params.value.length > 1) {
                return  'Year: ' + params['name'] + '<br/>' + params.seriesName + ' : ' +
                  '3A' + params.value
              } else {
                return  'Year: ' + params['name'] + '<br/>' + params.seriesName + ' : ' +
                  '3A' + params.value
              }
            } else {
              if (params.componentType === 'markPoint') {
                return ' Subject Company: ' + params.data.xAxis + '<br/>' +'Rating: ' + '3A' + params.data.yAxis
              }
            }
          }
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: data,
          splitLine: {
            show: true,
            lineStyle: {
              width: 1,
              type: 'dashed',
              color: '#8398BB'
            }
          },
          axisLine: {
            show: true,
            lineStyle: {
              width: 1,
              color: '#142746'
            }
          },
          axisLabel: {
            fontSize: fontSize,
            color: '#8398BB'
          },
          axisTick: {
            show: false
          }
        },
        yAxis: {
          type: 'value',
          max: 19,
          min: 1,
          splitNumber: 4,
          inverse: true,
          splitLine: {
            lineStyle: {
              width: 1,
              type: 'dashed',
              color: '#8398BB'
            }
          },
          axisLine: {
            show: true,
            lineStyle: {
              width: 1,
              color: '#142746'
            }
          },
          axisLabel: {
            fontSize: fontSize,
            color: '#8398BB',
            formatter: '3A{value}'
          },
          axisTick: {
            show: false
          }
        },
        series: [
          {
            name: '最高',
            type: 'line',
            smooth: true,
            data: this.xAxisnum,
            markPoint: {
              data: [
                {
                  symbolSize: '20',
                  silent: true,
                  yAxis: five,
                  xAxis: v
                }
              ],
              itemStyle: {
                color: '#ED5565'
              }
            }
          },
          {
            name: 'Worst',
            type: 'line',
            smooth: true,
            data: this.yAxisnum
          },
          {
            name: 'Average',
            type: 'line',
            smooth: true,
            data: this.zAxisnum
          },
          {
            name: 'Subject Company',
            type: 'line',
            smooth: true,
            data: this.wAxisnums
          }
        ]
      }
      this.secondChart.setOption(option)
      this.secondChart.resize()
    },
    echarts4 (fontSize, pieFontSize, iconWidth) {
      if (this.fourChart !== null) {
        this.fourChart.dispose()
      }
      this.fourChart = this.$echarts.init(this.$refs.echarts4)
      const option = {
        tooltip: {
          formatter: (params) => {
            if (params.value > 1) {
              return  ' Rating: ' + params['name'] + '<br/>' +  'Number of Company : ' + params['value'] + '<br/>' +  '% : ' + params['percent'] + '%'
            } else {
              return  'Rating : ' + params['name'] + '<br/>' +  ' Number of Company: ' + params['value'] + '<br/>' +  ' %: ' + params['percent'] + '%'
            }
          }
        },
        series: [
          {
            type: 'pie',
            clockwise: false,
            startAngle: 270,
            center: ['50%', '50%'],
            radius: [0, '70%'],
            label: {
              show: true,
              fontSize: pieFontSize,
              formatter: function (params) {
                if (params.data.isShow) {
                  return (
                    '{per|' +
                    params.percent +
                    '%} \n {b|' +
                    params.name +
                    '} {nameStyle| }'
                  )
                } else {
                  return (
                    '{per|' + params.percent + '%} \n {b|' + params.name + '}'
                  )
                }
              },
              rich: {
                nameStyle: {
                  width: iconWidth,
                  height: iconWidth,
                  backgroundColor: {
                    image: this.start1
                  }
                },
                per: {
                  color: '#ED5565',
                  fontSize: pieFontSize
                }
              }
            },
            labelLine: {
              length: 5,
              length2: 30
            },
            itemStyle: {
              label: {
                show: true,
                formatter: '{b}:{c}\n({d}%)'
              },
              color: function (params) {
                const colorList = [
                  '#138546',
                  '#01B152',
                  '#87C547',
                  '#FFDE2A',
                  '#F79024',
                  '#EE2424'
                ]
                return colorList[params.dataIndex]
              },
              borderWidth: 0,
              labelLine: { show: true }
            },
            data: this.List
          }
        ]
      }
      this.fourChart.setOption(option)
      this.fourChart.resize()
    },
    resizeFn () {
      if (document.body.clientWidth > 1664) {
        this.iconWidth = 16
        this.iconWidth1 = 14
        this.fontSize = 14
        this.pieFontSize = 14
      } else {
        this.iconWidth = 10
        this.iconWidth1 = 8
        this.fontSize = 14
        this.pieFontSize = 14
      }
      this.echarts1(this.fontSize)
      this.echarts2(this.fontSize, this.iconWidth)
      this.echarts4(this.fontSize, this.pieFontSize, this.iconWidth1)
    }
  }
}
</script>

<style scoped>
.title {
  border: 1px solid #e9ecf0;
}
.lengedBoxItems:nth-child(4) {
  margin-right: 0;
}
.mt-5{
  margin-top:5px;
}
::v-deep.el-table--scrollable-x ::-webkit-scrollbar {
  display: none;
}
.el-table ::v-deep .cellBg{
  background-color:#ED5565 !important;
  color:#ffffff
}
.cellBg .active{
  color: #ffffff !important;
}
/* 1920 */
@media (min-width: 1665px) {
.el-table-filter {
    max-height: 350px;
    overflow: auto;
}
  ::v-deep.el-table th{
    height: 40px;
  }
  ::v-deep .el-table__row td{
    height: 30px;
    font-size: 12px !important;
  }

  .rateEchartItems1 {
    width: 50%;
    height: 27.8571429rem;
    margin: 0 auto;
  }
  .rateEchartItems2 {
    width: 50%;
    height: 27.8571429rem;
    margin: 0 auto;
  }
  .rateEchartItems3 {
    width: 90%;
    height: 33.5rem;
    margin: 0px 20px 0px auto;
    overflow: auto;
  }

  .rateEchartItems4 {
    width: 50%;
    height: 27.8571429rem;
    margin: 20px auto 40px auto;
    /* padding-top: 80px;
    padding-left: 92px; */
  }

  /* .lengedBox {
    top: 60px;
    right: 70px;
    width: 360px;
  }
  .lengedBarBox {
    top: 0;
    right: 100px;
  }

  .lengedLineBox1 {
    top: 60px;
    right: 95px;
  }
  .lengedLineBox {
    top: 3px;
    right: 95px;
  } */

  .lengedBox {
    top: 120px;
    right: 70px;
    width: 360px;
  }
  .lengedBarBox {
    top: 58px;
    right: 100px;
  }
  .lengedLineBox1 {
    top: 118x;
    right: 95px;
  }
  .lengedLineBox {
    top: 58px;
    right: 95px;
  }

  .rateIndustrySelect ::v-deep .el-input__inner {
    height: 38px;
    padding-left: 20px;
    border-radius: 20px;
  }
  .rateIndustrySelect ::v-deep .el-input__suffix {
    right: 15px;
  }
  .rateIndustrySelect ::v-deep .el-input.is-focus .el-input__inner {
    border-radius: 20px;
  }
  /* .rate_scale_table{
    height: 100%;
  } */
}
/* 1280*/
@media (max-width: 1664px) {
  .flex_center_between_box{
    flex-wrap: wrap;
  }
  .el-table-filter {
    max-height: 250px;
    overflow: auto;
}
  ::v-deep.el-table th{
    height: 30px;
  }
  ::v-deep .el-table__row td{
    height: 26px;
  }
  .rateEchartItems1 {
    width: 100%;
    height: 27.8571429rem !important;
    margin: 0 auto;
  }

  .rateEchartItems2 {
    width: 100%;
    height: 27.8571429rem !important;
    margin: 0 auto;
  }

  .rateEchartItems3 {
    width: 100%;
    height: 27.8571429rem !important;
    margin: 14px auto 0 auto;
  }

  /* .rateEchartItems4 {
    width: 386px;
    height: 238px;
    margin: 34px auto 0 auto;
  } */
  .rateEchartItems4 {
    width: 100%;
    height: 27.8571429rem !important;
    margin: 14px auto 0 auto;
    /* padding-top: 60px;
    padding-left: 62px; */
  }
  /* .lengedBox {
    top: 40px;
    right: 46px;
    width: 295px;
  }
  .lengedBarBox {
    top: 0;
    right: 66px;
  }

  .lengedLineBox1 {
    top: 40px;
    right: 55px;
  }
  .lengedLineBox {
    top: 2px;
    right: 55px;
  } */
  .lengedBox {
    top: 78px;
    right: 46px;
    width: 295px;
  }
  .lengedBarBox {
    top: 36px;
    right: 66px;
  }
  .lengedLineBox1 {
    top: 76px;
    right: 55px;
  }
  .lengedLineBox {
    top: 36px;
    right: 55px;
  }

  .rateIndustrySelect ::v-deep .el-input__inner {
    height: 26px;
    padding-left: 14px;
    border-radius: 14px;
  }
  .rateIndustrySelect ::v-deep .el-input__suffix {
    right: 10px;
  }
  .rateIndustrySelect ::v-deep .el-select__caret {
    line-height: 26px;
  }
  .rateIndustrySelect ::v-deep .el-input.is-focus .el-input__inner {
    border-radius: 14px;
  }
    .rate_scale_table{
    height: 250px;
    overflow: auto;
  }
}
.rateEchartItems3 /deep/ .el-table tr{
  height: 2.85714286rem !important;
}
</style>
